.m50 {

    h3 {
        @media (max-width: 991px) {
           font-size: 24px;
        }
    }
    
    p {
        font-size: 18px;
    }
    li {
        font-size: 18px;
    } 
    .justify-content-center {
        background-color: #e8e8e8;
    }
    .image-box {
        padding: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .background-shape {
        height: 100%;
        width: 80px;
        position: absolute;
        right: 0;
        top: 0;
        background: linear-gradient(to bottom right, transparent 0%, transparent 49%, #fafafa 50%, #fafafa 100%);
        @media (max-width: 991px) {
            display: none;
        }
    }
    .info-box {
        background-image: linear-gradient(to right, #fafafa, #dbdbdb);
        @media (max-width: 991px) {
            background-image: linear-gradient(to bottom, #fafafa, #dbdbdb);
        }
        padding: 30px 30px 25px 20px;
        p {
            line-height: 25px;
        }
        ul {
            padding-left: 24px;
            li {
                line-height: 25px;
                padding-bottom: 5px;
            }
            li::marker {
                color: #e67800;
            }
        }
        
    }
    .info-box.form-on-the-right ul li {
        padding-bottom: 30px;
        @media (max-width: 991px) {
            padding-bottom: 5px;
        }
    }
}