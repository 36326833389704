.m17 {
  @media (min-width: $grid-float-breakpoint) {
    margin-top: 200px;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    text-align: center;
  }

  .tab-navigation {
    margin-top: -200px;
    margin-bottom: 150px;
  }

  blockquote {
    color: $orange;
    font-family: $roboto-slab;
    font-weight: $light;
    margin-bottom: 10px;
  }

  .regular16 {
    @media (min-width: $grid-float-breakpoint) {
      float: left;

      &:after {
        content: " ";
        background: $orange;
        height: 4px;
        width: 4px;
        display: inline-block;
        float: right;
        border-radius: 50%;
        margin: 11px 15px 0px 15px;
      }
    }
  }

  .m17-content {
    padding-top: 15px;
  }

  .bullet {
    margin: 15px auto;
    display: block;
    width: 4px;
    height: 4px;
    background: #e67800;
    outline: none;
    border-radius: 100%;
  }

  h6 {
    color: $orange;
    text-transform: uppercase;
  }

  .shadow-small {
    &.bcg-white {
      @media (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 30px;
        max-width: 450px;
      }

      .m17-box-item {
        padding: 25px 20px;
        border-bottom: 1px solid $grey-medium;

        p {
          margin-bottom: 0px;
        }
      }

    }
  }

  .nav {
    .nav-item {
      a {
        color: $dark;
        border: none;
        font-size: 16px !important;
        padding: 0;
        margin: 0 15px;
        opacity: 0.3;
        font-weight: 600 !important;
        line-height: 30px !important;

        &.active {

          opacity: 1;

          border-bottom: 2px solid $orange;
        }
      }
    }
  }

  .swiper-pagination {
    position: relative;
    display: block;
  }

  .swiper-slide {
    h6 {
      @media (max-width: $grid-float-breakpoint-max) {
        color: $dark;
        margin-bottom: 17px;
      }
    }
  }

  .swiper-pagination-bullets {
    position: relative;
    text-align: center;
    z-index: 10;

    @media (max-width: $grid-float-breakpoint-max) {
      bottom: 0px;
      left: 0;
      width: 100%;
    }

    .swiper-pagination-bullet {
      @media (max-width: $grid-float-breakpoint-max) {
        margin: 0 20px;
        width: 6px;
        height: 6px;
        background: $dark;
        opacity: .2;
        outline: none;
      }
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background: $primary;

      &:focus {
        outline: none;
      }

      p {
        border: 2px solid $primary;
        padding: 15px;
        border-radius: 50%;
        position: absolute;
        margin: -14px -14px;
        line-height: 0;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .more-info {
    text-align: left;
    margin: 20px 0px;
    padding-left: 0px;

    .close-panel {
      display: none;
    }

    .open-panel {
      display: block;
    }

    a {
      padding: 0px;
      text-decoration: none;

      &:after {
        @media (max-width: $grid-float-breakpoint-max) {
          content: "";
          display: inline-block;
          position: absolute;
          background-image: url(./images/arrow-down.svg);
          background-repeat: no-repeat;
          width: 9px;
          height: 9px;
          top: -3px;
          margin-left: 45px;
          margin-top: 14px;
        }
      }
    }

    a[aria-expanded="true"] {
      .close-panel {
        display: block;
      }

      .open-panel {
        display: none;
      }

      &:after {
        @media (max-width: $grid-float-breakpoint-max) {
          content: "";
          display: inline-block;
          position: absolute;
          background-image: url(./images/arrow-up.svg);
          background-repeat: no-repeat;
          width: 9px;
          height: 9px;
          top: -3px;
          margin-left: 55px;
          margin-top: 14px;
        }
      }

    }
  }

  .m17-box-item-title {
    h6 {
      color: $orange;
    }
  }

  @media (max-width: $grid-float-breakpoint-max) {
    .shadow-small {
      box-shadow: none;
    }
  }
}