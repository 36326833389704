
body {

  &.customize-support{
    //padding-top: 32px;
    #navbar {
      padding-top: 32px;
    }
  }
  position: relative;
  
  @media (max-width: $grid-float-breakpoint-max) {
    #tbackground {
      display: none;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.8);
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 110;
    }
  } 
  
}
.page-with-children {
  margin-top: 94px;
  @media (max-width: 1643px){
    margin-top: 140px;
  }
  @media (max-width: 1275px){
    margin-top: 135px;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    margin-top: -15px;
  }
}
.page {
  padding: 80px 0px;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 40px 0px;
  }
  &.hero {
    padding: 100px 0px;
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 110px 0px;
    }
  }
  &.m4-content {
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0px 0px 40px;
    }
  }

  &.m26-content {
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0px 0px;
    }
  }

  &.m25 {
    padding: 0px 0px 80px 0px;
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0px 0px;
    }
  }

  &.section-half-gray {
    @media (min-width: $grid-float-breakpoint) {
      background-color: #f6f6f6;
      background: linear-gradient(90deg, #FFFFFF 50%, #f6f6f6 50%);
      width: 100%;
    }

    &.m17 {
      background: linear-gradient(0deg, $grey-light 70%, #FFFFFF 70%);

      @media (max-width: $grid-float-breakpoint-max) {
        background: linear-gradient(180deg, #fff 250px, $grey-light 250px)
      }
    }
  }

  &.section-first-half-gray {
    @media (min-width: $grid-float-breakpoint) {
      background-color: #f6f6f6;
      background: linear-gradient(90deg, #f6f6f6 50%, #ffffff 50%);
      width: 100%;
    }
  }
  &.section-first-more-half-gray {
    @media (min-width: $grid-float-breakpoint) {
      background-color: #f6f6f6;
      background: linear-gradient(90deg, #f6f6f6 60%, #ffffff 40%);
      width: 100%;
    }
  }

  &.section-half-gray-home {
    @media (min-width: $grid-float-breakpoint) {
      background-color: #f6f6f6;
      background: linear-gradient(90deg, #FFFFFF 50%, #f6f6f6 50%);
      width: 100%;
      opacity: 1;
      transition: all 0.5s linear;
    }
  }


  &.section-half-gray-invert {
    @media (min-width: $grid-float-breakpoint) {
      background-color: #f6f6f6;
      background: linear-gradient(90deg, #f6f6f6 50%, #ffffff 50%);
      width: 100%;
    }
  }

  &.section-third-gray-right {
    @media (min-width: $grid-float-breakpoint) {
      background-color: #f6f6f6;
      background: linear-gradient(90deg, #FFFFFF 17%, #f6f6f6 17%);
      width: 100%;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      background-color: #f6f6f6;
      background: linear-gradient(0deg, #f6f6f6 85%, #FFFFFF 85%);
      height: 100%;
    }
  }
  &.section-third-gray-left {
    @media (min-width: $grid-float-breakpoint) {
      background-color: $grey-light;
      background: linear-gradient(90deg, $grey-light 85%, $white 17%);
      width: 100%;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      background-color: #f6f6f6;
      background: linear-gradient(0deg, $white 19%, $grey-light 19%);
      height: 100%;
    }
  }
  .section-half-gray {
    @media (min-width: $grid-float-breakpoint) {
      background-color: #f6f6f6;
      background: linear-gradient(90deg, #FFFFFF 50%, #f6f6f6 50%);
      width: 100%;
    }

  }

  img {
    max-width: 100%;
  }

  .btn-one {
    position: absolute;
    /*margin-left: 117px; */ margin-left: 0px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-left: 0px;  // u kane 80px
      position: inherit; // ja kam shtu vet
    }


    a {
      padding-left: 0px;
    }
    span {
      background: white;
      border-radius: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
      border-color: white;
      padding: 15px 15px;

      @media (max-width: $grid-float-breakpoint-max) {
        margin-left: 0; // u lkane 2vw
      }
    }
    /*
    &:before {
      content: " ";
      width: 130px;
      height: 2px;
      vertical-align: super;
      background-color: #e67800;
      display: inline-block;
      pointer-events: none;
      margin-left: -117px;
      position: absolute;
      top: 16px;
      @media (max-width: $grid-float-breakpoint-max) {
        z-index: -2;
        margin-left: -80px;
      }
    }
    */
  }

  .shadow-small {
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    border-color: white;
  }
  .bcg-white {
    background: $white;
  }
  //buton i thjeshte
  .btn-arrow {
    background: white;
    border-radius: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    border-color: white;
    padding: 0 15px;
    margin: 0 auto;
    a {
      padding: 0 12px;
    }
  }
  .orange {
    color: $orange;
  }
  .img-op {
    filter: brightness(80%);
  }
}
.language-modal {
  text-align: center;
  .close {
    opacity: 1!important;
    span {
      color: #E67800;
      font-weight: 300;
      text-shadow: none;
      border: 2px solid #E67800;
      border-radius: 50%;
      padding: 0 8px 2px 8px;
    }
  }
  .modal-content {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    border: 0;
    .modal-header {
      border: 0;
    }
  }
  a {
    font-size: 16px;
  }
  .modal-dialog {
    max-width: 788px;
  }
  .modal-body {
    padding: 10px 67px 44px;
    @media (max-width: $grid-float-breakpoint) {
      padding: 10px 15px 30px;
    }
  }
}