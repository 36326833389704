.m36 {
  .flex-even {
    flex: 1;
  }
  .img-responsive {
    width: 100%;
    margin-bottom: 20px;
  }
  .regular16 {
      padding: 0;
      margin: 0;
  }
  .light16 {
    margin-top: 20px;
    margin-bottom: 25px;
  }
  .morelink {
    display: table;
    font-weight: 300;
    line-height: 19px;
    border: 0;
    color: $orange;
    margin-top: 6px;
    &:after {
      content: "";
      display: inline-block;
      background-image: url(./images/arrow-line.svg);
      background-repeat: no-repeat;
      width: 9px;
      height: 9px;
      margin-left: 8px;
      margin-right: 3px;
    }
    &.less {
      &:after {
        transform: rotate(180deg);
        margin-bottom: 3px;
      }
    }
  }
  .item-social {
    margin-bottom: 22px;

    .m27cta {
      font-weight: 500;
      @media (min-width: $grid-float-breakpoint) {
        margin-right: 20px;
      }

      &:after {
        content: "";
        display: inline-block;
        background-image: url(./images/btn-arrow.svg);
        background-repeat: no-repeat;
        width: 9px;
        height: 9px;
        margin-left: 8px;
      }
    }
  }

  .swiper-h-pagination-2 {
		margin: 50px 0px 0;

		@media (max-width: $grid-float-breakpoint-max) {
			margin: 25px 0px 25px;
		}
	}

  .swiper-button-prev, .swiper-button-next {
    width: 30px;
    height: 50px;
    background-size: 50px 50px;
}


  .swiper-button-prev, .swiper-button-next {
    position: absolute;
    @media (max-width: 1199px) {
			top: 21%;
		}
    @media (max-width: 787px) {
      top: 18%;
      width: 30px;
      height: 27px;
		}
  }

  .swiper-button-prev {
    left: -55px;
    @media (max-width: 1199px) {
			left: 0;
		}
    background-image: url(./images/svg/arrow-swiper.svg);
  }
  .swiper-button-next {
    right: -55px;
    @media (max-width: 1199px) {
			right: 0;
		}
    background-image: url(./images/svg/_arrow-swiper-right.svg);
  }


	.swiper-pagination-bullets {
		position: relative;
		text-align: center;
		z-index: 10;

		bottom: 10px;
		left: 0;
		width: 100%;

		.swiper-pagination-bullet {
			margin: 0 20px;
			width: 6px;
			height: 6px;
			background: #000;
			opacity: .2;
			outline: none;
		}

		.swiper-pagination-bullet-active {
			opacity: 1;
			background: $primary;

			&:focus {
				outline: none;
			}

			p {
				border: 2px solid $primary;
				padding: 15px;
				border-radius: 50%;
				position: absolute;
				margin: -14px -14px;
				line-height: 0;

				&:focus {
					outline: none;
				}
			}
		}
	}

  
}