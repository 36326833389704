.m15 {
  h3 {
    margin-bottom: 58px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 20px;
    }
  }
  .margin-top-60 {
    margin-top: 60px;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-top: 25px;
    }
  }
}