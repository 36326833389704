.m42 {
  .row {
    margin: 0;
  }

  .box {
    background: $white;
    border: solid 1px $grey-light;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding-right: 0;
    @media screen and (max-width: $mobile-breakpoint) {
      padding-left: 0;
    }

    .col-lg-6.pr-0.order-1.order-lg-2 {
      @media screen and (max-width: $mobile-breakpoint) {
        padding-left: 0;
      }
    }
  }

  .image-box {
    img {
      @media screen and (min-width: $mobile-breakpoint+1) {
        display: none;
      }
    }

    background-size: cover !important;
    @media screen and (max-width: $mobile-breakpoint) {
      background: none;
    }

    &.image-only {
      img {
        @media screen and (min-width: $mobile-breakpoint+1) {
          display: block;
          background: none;
        }
      }
    }
  }

  .content-box {

    padding-top: 40px;
    padding-bottom: 65px;
    padding-left: 35px;
    @media screen and (max-width: $mobile-breakpoint) {
      padding-top: 34px;
      padding-bottom: 32px;
      padding-left: 30px;
    }

    p {
      font-weight: 300;

      &.light14 {
        font-family: $roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        letter-spacing: 1.5px;
        color: #e67800;
        border-bottom: 0;
        text-transform: uppercase;

        img {
          margin-right: 10px;
        }
      }
    }

    .light20 {
      max-width: 576px;

      p, a {
        font-weight: $light;
        font-size: 20px;
        line-height: 30px;
        @media screen and (max-width: $mobile-breakpoint) {
          font-size: 16px;
          line-height: 25px;
          a {
            font-size: 16px;
          }
        }

        a {
          font-size: 20px;
          @media screen and (max-width: $mobile-breakpoint) {

            font-size: 16px;
          }
        }
      }
    }

    &.text-only {
      .light20 {
        max-width: 505px;
      }

      .row {
        @media screen and (max-width: $mobile-breakpoint) {
          margin-left: -15px;
        }
      }
    }

    h3 {
      font-weight: bold;
      margin-top: 30px;
    }
  }

  .read-article {
    text-transform: uppercase;
    border: none;
    letter-spacing: 1.5px;
    font-weight: $medium !important;

    &.small-light {
      font-size: 14px;
      font-weight: $light;
      letter-spacing: 0.61px;
      line-height: 25px;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 14px;
        font-weight: $light;
        letter-spacing: 0.22px;
        line-height: 20px;

      }
    }

    &:after {
      content: "";
      display: inline-block;
      background-image: url(./images/btn-arrow.svg);
      background-repeat: no-repeat;
      width: 10px;
      height: 10px;
      margin-left: 8px;
    }

    &:hover {
      color: $orange;
    }

  }
}