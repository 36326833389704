.m3 {
	&.page {
		padding-bottom: 120px;
		@media screen and (max-width: $mobile-breakpoint) {
			padding-bottom: inherit;
		}
	}
	h1 {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 100px;
		text-align: center;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 60px;
		}
	}
	h2 {
		font-family: $rajdhani;
		font-weight: $bold;
		font-size: 60px;
		line-height: 70px;
		letter-spacing: 1px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 30px;
			line-height: 35px;
		}

		margin-left: auto;
		margin-right: auto;
		margin-bottom: 100px;
		text-align: center;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 60px;
		}
	}
	h3 {
		margin: 37px 0 26px 0;
		word-wrap: break-word;
	}
	p {
		margin-bottom: 15px;
	}
	p:last-of-type {
		margin-bottom:  47px;
	}
	.before-box {
	    border: 20px solid $grey-light;
	    padding: 0;
	}
	.box {
		text-align: center;
		border: solid 1px $grey-light;
		box-shadow: 0 0 10px rgba(0, 0, 0, .2);
		padding: 0 15px;
		@media screen and (max-width: $mobile-breakpoint) {
			padding: 0 20px;
		}
	}
	.bullet {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 15px;
	    display: block;
	    width: 4px;
	    height: 4px;
	    background: $orange;
	    outline: none;
	    border-radius: 100%;
	}
	.bullet:first-of-type {
		display: none;
	}
	.vertical-line-top {
	  position: absolute;
	  background-color: $grey-medium;
	  width: 2px;
	  height: 80px;
	  top: -100px;
	  left: 50%;
	  @media screen and (max-width: $mobile-breakpoint) {
	  	height: 40px;
	  	top: -60px;
	  }
	}
	.vertical-line-bottom {
	  position: absolute;
	  background-color: $grey-medium;
	  width: 2px;
	  height: 80px;
	  bottom: -100px;
	  left: 50%;
	  @media screen and (max-width: $mobile-breakpoint) {
	  	height: 40px;
	  	bottom: -60px;
	  }
	}
}