.m7 {
  padding: 100px 0px 100px 0px;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 80px 0px 0px 0px;
  }

  video {
    object-fit: cover;
    height: auto;
  }  

  .container {
    &.cm7 {
      @media (max-width: $grid-float-breakpoint-max) {

      }

      .m7-left {
        @media (max-width: $grid-float-breakpoint-max) {
          padding: 0px;
        }

      }
    }

  }

  .m7-header-left {
    position: relative;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      box-shadow: 1px 1px 10px $grey-dark;
      z-index: 2;
    }

    h1 {
      color: $white;
      position: absolute;
      top: 60px;
      left: 50px;
      max-width: 600px;

      @media (max-width: $grid-float-breakpoint-max) {
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;

      }

    }

    .dv-header {
      @media (max-width: $grid-float-breakpoint-max) {
        height: 330px;
       &.dv-header-video{
        height: initial;
        video {
          margin-bottom: -7px;
        } 
       }  
      }

      img {
        @media (max-width: $grid-float-breakpoint-max) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .m7-logo {
      position: absolute;
      right: 0px;
      bottom: 0px;
      margin:0px;
      padding:0px;
      box-shadow: 1px 1px 10px $grey-dark;
      @media (max-width: $grid-float-breakpoint-max) {
        position: inherit;
        box-shadow: none;
      }
    }
  }

  .m7-text {
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: left;
      background: $grey-light;
      padding-top: 28px;
      padding-bottom: 32px;
      padding-left: 0px;

    }

    h2 {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px 20px 0px 0px;
      }
    }
  }
}