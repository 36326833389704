.m16 {
	@media screen and (max-width: $mobile-breakpoint) {
		.no-padding {
			padding: 0px 15px;
		}
		.hide-mobile {
			display: none;
		}
	}
	.no-padding {
		@media screen and (max-width: $mobile-breakpoint) {
			padding: 0px 15px;
		}
	}
	.align_center {
		text-align: center;
	}
	.col-lg-10 {
		padding: 0px;
	}
	.margine-top {
		margin-top: 55px;
	}
	h2 {
		margin-bottom: 65px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 14px;
		}	
	}
	p.regular16 {
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 36px;
		}	
	}
	.grey_box
	{
		background: $grey-light;
		@media screen and (max-width: $mobile-breakpoint) {
			margin: 0 15px;
		}		
	}
	.orange_border
	{
		border: 2px solid $orange;
		padding: 90px 0px;
		@media screen and (max-width: $mobile-breakpoint) {
			padding: 25px 0px;
		}		
	}
	img {
		width: 100%;
		height: auto;
		&.image-svg {
			width: 45px;
			height: 45px;
			margin-bottom: 25px;
		}
	}
	.before-icon-box {
		margin-bottom: 50px;
	}
	.no-margin {
		margin: 0px!important;
	}
	.round_icon {
		position: relative;
		background: $white;
		border: none;
		border-radius: 50%;
		width: 100%;
		margin: 0 auto;
		padding: 0 20px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 24px;
		}
	}
	.btn-arrow {
		margin-bottom: 50px;
		margin-top: 30px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-top: 0;
			margin-bottom: 30px;
		}
		img {
			width: 5px;
			margin: 0;
			margin-left: 4px;
		}
	}
	.info-img-box {
		max-width: 220px;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
	.row-with-padding {
		padding: 0;
		@media screen and (min-width: 1555px) {
			padding: 0 6%;
		}
	}
}