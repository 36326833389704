.m1 {
  @media (max-width: $mobile-breakpoint){
    padding: 110px 0 40px;

    
  }
  .same-height3 {
    //height: 200px;
    object-fit: cover;
}
  .horizontal-mobile {
    display: none;
    @media screen and (max-width: $mobile-breakpoint) {
      display: block;
      margin-left: -15px;
    }
  }
  .padding-none {
    padding: 0;
    @media (min-width: 992px) and (max-width: 1199px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  h1 {
    @media screen and (max-width: 991px) {
          font-size: 30px;
          line-height: 30px;
      }
  }
  .swiper-container {
    &.home-slider {
      width: 100%;



      @media (min-width: $grid-breakpoints-xl) {
        min-height: 625px;
      }

      @media (min-width: 1700px) {
        min-height: 670px;
      }

      @media (max-width: $grid-float-breakpoint-max) {
        text-align: center;
        min-height: 765px;
      }
      @media (max-width: 991px) {
        min-height: initial;
      }

    }
  }
  .swiper-slide {
    @media (max-width: $grid-float-breakpoint-max) {
      background: $grey-light;
      padding-bottom: 30px;
    }
  }
  .swiper-pagination-bullets {
    position: absolute;
    text-align: center;
    z-index: 10;

    @media (max-width: $grid-float-breakpoint-max) {
      bottom: 19px;
      left: 0;
      width: 100%;
      &.no-logo-padding {
         // bottom: 22%!important;
      }
    }
    @media (min-width: $grid-float-breakpoint) {
      right: 50px;
      top: 28%;
      transform: translate3d(0, -50%, 0);
    }

    .swiper-pagination-bullet {
      @media (max-width: $grid-float-breakpoint-max) {
        margin: 0 20px;
        width: 6px;
        height: 6px;
        background: #000;
        opacity: .2;
        outline: none;
      }
      @media (min-width: $grid-float-breakpoint) {
        margin: 34px 0;
        display: block;
        width: 6px;
        height: 6px;
        background: #000;
        opacity: .2;
        outline: none;
      }

    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background: $primary;

      &:focus {
        outline: none;
      }

      p {
        border: 2px solid $primary;
        padding: 15px;
        border-radius: 50%;
        position: absolute;
        margin: -14px -14px;

        &:focus {
          outline: none;
        }

      }

    }
  }
  .slider-title {
    @media (max-width: $grid-float-breakpoint-max) {
      background: $white;
    }
    @media (min-width: $grid-float-breakpoint) {
      position: relative;
    }
  }
  .s-title {
    color: $orange;
    @media (min-width: $grid-float-breakpoint) {
      position: relative;
    }
  }
  .d-title {
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 36px;

    }
    @media (min-width: $grid-float-breakpoint) {
      min-height: 120px;
      position: absolute;
      max-width: 570px;
    }
  }
  .slider-description {
    @media (max-width: $grid-float-breakpoint-max) {
      padding-top: 35px;
      margin-bottom: -15px;
      height: 150px;
      h3 {
        margin-bottom: 0;
      }
    }
    @media screen and (max-width: $mobile-breakpoint) {
      h3 {
        &.no-logo-padding {
          //height: 100px!important;
        }
      }
    }
  }

  .m1-right-img {
    @media (min-width: $grid-float-breakpoint) {
      margin-top: 70px;
    }
    position: relative;
    .logo-panel {
      .img-logo {
        @media (min-width: $grid-float-breakpoint) {
          position: absolute;
          max-width: 260px;
          bottom: 0px;
          right: 0px;
          box-shadow: 0 0 10px rgba(0, 0, 0, .2);
        }
        @media (max-width: $grid-float-breakpoint-max) {
          max-width: 260px;
        }
      }
      @media (max-width: $grid-float-breakpoint-max) {
        box-shadow: 0 0 10px rgba(0, 0, 0, .2);
        background: $white;
      }
    }

    .logo-panel-empty {
      display: none;
      @media (max-width: $grid-float-breakpoint-max) {
        display: block!important;
        background-color: #f6f6f6;
        content: ' ';
        height: 60px;
      }
    }

  }
  .home-arrow-div {
    @media (max-width: $grid-float-breakpoint-max) {
      display: none;
    }
    position: relative;
    height: 1px;
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 180px;
    }

    @media (min-width: 1200px) and (max-width: 1400px) {
      width: 190px;
    }
    margin: 0 auto;
    text-align: center;

    .home-arrow {
      width: 261px;
      margin-top: 28px;
    }
  }

  .home-arrow-div-down {
    @media (max-width: $grid-float-breakpoint-max) {
      margin-right: 15px;
    }
    img {
      position: absolute;
      bottom: -28px;
    }
  }
  .butoni {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: none;
    }
    display: inline-grid;
    margin: 0;
    padding: 0px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding: 15px 50px;
    }
    background: white;
    border-radius: 0;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-color: white;
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-left: 3px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 15px 30px;
      }
    }
    /*
    a {
    @media screen and (min-width: 992px) {
        &:before {
          content: '';
          position: absolute;
          border-bottom: 2px solid #e67800;
          width: 34%;
          height: 15px;
          left: -34%;
        }
      }
    }
    */
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 0 auto;
    }
    img {
      width: 5px;
      margin: 0px;
      margin-left: 4px;
    }
    .bttn {
      font-size: 14px;
      letter-spacing: 1.5px;
      line-height: 30px;
      padding: 15px 50px;
      margin: 0;
      border: 0;
      text-transform: uppercase;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 0;
      }
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 15px 30px;
        font-size: 12px;
        letter-spacing: 1.3px;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          padding: 0;
        }
      }
    }
    &.butoni-mobile {
      display: none;
      @media screen and (max-width: $mobile-breakpoint) {
        display: inline-grid;
        margin-top: 40px;
        z-index: 999;
      }
    }
    
    @media screen and (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
  hr {
    margin: 0;
    height: 1px;
    background: #e67800;
    margin-left: -47%;
    margin-top: -30px;
    width: 70%;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      display: none;
    }
    @media screen and (max-width: $mobile-breakpoint) {
      display: block;
      margin: 0;
      height: 1px;
      background: $orange;
      margin-left: 0;
      margin-top: -30px;
      width: 50%;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: none;
    }
  }
  .homeslider-cta {
    margin-top: -80px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: -100px;
      margin-top: 100px;
      &.no-logo-padding {
        margin-bottom: -119px!important;
        margin-top: 66px!important;
      }
    }
  }
  .height-150 {
    height: 150px;
  }
  &.nologo {
    .logo-panel-empty {
      display: none!important;
    }
  }
  hr.desktop-only {

    @media (max-width: $mobile-breakpoint) {
      display: none;
    }
  }
}