.m45 {
  @media (max-width: 600px) {
    background: #f6f6f6;
  }

  position: relative;
  .container {
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }
  @media screen and (min-width: $mobile-breakpoint+1) {
    padding: 0;
  }


  .new-header-slider {
    display: flex;
    align-items: center;
    @media screen and (min-width: $mobile-breakpoint+1) {
      width: initial !important;
      padding: initial !important;
      flex: initial;
      position: absolute;
      left: 0;
      height: 100%;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 60px 0;
      background: transparent;
      text-align: center;
    }
    @media (max-width: 600px) {
      padding: 25px 25px 0 25px;
      background: #f6f6f6;
    }

    .slider-content {
      background: #f6f6f6;
      @media screen and (min-width: $mobile-breakpoint+1) {
        width: 970px;
        position: absolute;
        z-index: 1;
        padding: 72px 0 85px 142px;
      }

      @media (min-width: $grid-float-breakpoint-max) and (max-width: 1500px) {
        width: 750px;
      }
      @media (min-width: $grid-float-breakpoint-max) and (max-width: 1150px) {
        padding: 50px 24px 50px 110px;
      }

      @media (max-width: $grid-float-breakpoint-max) {
        position: absolute;
        width: calc(100% - 80px);
        padding: 50px 0 25px 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
        margin: 0 auto!important;
      }
      @media (max-width: 600px) {
        position: initial;
        width: 100%;
        padding: 0;
      }


      .pre-title {
        p {
          display: inline-block;
          color: $orange;
          font-weight: 500;
          @media (max-width: $grid-float-breakpoint-max) {
            margin-bottom: 10px;
          }
          &:last-child {
            margin-left: 10px;
          }
        }
        @media (max-width: $grid-float-breakpoint-max) {
          max-width: 449px;
          margin: 0 auto;
        }
        .circle {
          display: inline-block;
          background-color: $orange;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          margin-bottom: 3px;
          margin-left: 8px;
        }
      }

      .title {
        max-width: 686px;
        @media (max-width: $grid-float-breakpoint-max) {
          max-width: 449px;
        }
        @media (max-width: $grid-float-breakpoint-max) {
          margin-left: auto;
          margin-right: auto;
        }

        h1 {
          font-family: $rajdhani;
          color: $orange;
          font-weight: bold;
          line-height: 1.17;
          @media (min-width: $grid-float-breakpoint-max) and (max-width: 1500px) {
            font-size: 40px;
            line-height: 1;
          }
        }
      }

      .text {
        max-width: 544px;
        @media (max-width: $grid-float-breakpoint-max) {
          margin-left: auto;
          margin-right: auto;
          max-width: 449px;
        }
      }

      .link {
        a {
          @media (max-width: $grid-float-breakpoint-max) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .swiper-pagination-bullets {
    position: absolute;
    text-align: center;
    z-index: 10;


    @media (max-width: $grid-float-breakpoint-max) {
      bottom: 14px;
      width: 100%;
      position: initial;
      height: 30px;
      margin-top: 30px;
    }
    @media (min-width: $grid-float-breakpoint) {
      left: 50px;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      align-items: center;
    }

    .swiper-pagination-bullet {
      @media (max-width: $grid-float-breakpoint-max) {
        margin: 0 20px;
        width: 6px;
        height: 6px;
        background: #000;
        opacity: .2;
        outline: none;
      }
      @media (min-width: $grid-float-breakpoint) {
        margin: 34px 0;
        display: block;
        width: 6px;
        height: 6px;
        background: #000;
        opacity: .2;
        outline: none;
      }

    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background: $primary;

      &:focus {
        outline: none;
      }

      p {
        border: 2px solid $primary;
        padding: 15px;
        border-radius: 50%;
        position: absolute;
        margin: -14px -14px;
        line-height: 0;
        @media (max-width: $grid-float-breakpoint-max) {
          padding: 13px;
          margin: -12px -12px;
        }

        &:focus {
          outline: none;
        }

      }

    }
  }

}