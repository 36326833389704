.m8 {
  @media (min-width: $grid-float-breakpoint) {
    position: relative;
  }
  .grey_box {
    background: #f6f6f6;
    @media (min-width: $grid-float-breakpoint) {
      border-left: 15px solid $white;
      border-right: 15px solid $white;
    }
  }

  .mob-rrethi {
    width: 50px;
    height: 50px;
    background: white;
    border: 2px solid $orange;
    border-radius: 50%;
    margin: 24px auto 21px auto;

    h3 {
      padding: 0.60rem;
      padding-left: 0.7rem;
      border: none;
    }
  }
  .mob-rrethi-title {
    padding: 0px 30px;
  }
}

.radial-progress {
  margin: 50px auto;
  width: 500px;
  height: 500px;
  background-color: #d6dadc;
  border-radius: 500px;
}

.radial-progress .circle .mask,
.radial-progress .circle .fill,
.radial-progress .circle .shadow {
  width: 500px;
  height: 500px;
  position: absolute;
  border-radius: 500px;
}

.radial-progress .circle .shadow {
}

.radial-progress .circle .mask,
.radial-progress .circle .fill {
  transition: transform 1s;
  border-radius: 500px;
}

.radial-progress .circle .mask {
  clip: rect(0px, 500px, 500px, 250px);
}

.radial-progress .circle .mask .fill {
  clip: rect(0px, 250px, 500px, 0px);
  background-color: $orange;
}

.radial-progress .inset {
  width: 494px;
  height: 494px;
  position: absolute;
  margin-left: 3px;
  margin-top: 3px;
  background-color: #f3f3f3;
  border-radius: 50%;
}

.radial-progress[data-progress="0"] .circle .mask.full,
.radial-progress[data-progress="0"] .circle .fill {
  transform: rotate(0deg);
}

.radial-progress[data-progress="0"] .circle .fill.fix {
  transform: rotate(0deg);
}

.radial-progress[data-progress="0"] .inset .percentage .numbers {
  width: 57px;
}

.radial-progress[data-progress="20"] .circle .mask.full,
.radial-progress[data-progress="20"] .circle .fill {
  transform: rotate(36deg);
}

.radial-progress[data-progress="20"] .circle .fill.fix {
  transform: rotate(72deg);
}

.radial-progress[data-progress="20"] .inset .percentage .numbers {
  width: 1197px;
}

.radial-progress[data-progress="40"] .circle .mask.full,
.radial-progress[data-progress="40"] .circle .fill {
  transform: rotate(72deg);
}

.radial-progress[data-progress="40"] .circle .fill.fix {
  transform: rotate(144deg);
}

.radial-progress[data-progress="40"] .inset .percentage .numbers {
  width: 2337px;
}

.radial-progress[data-progress="60"] .circle .mask.full,
.radial-progress[data-progress="60"] .circle .fill {
  transform: rotate(108deg);
}

.radial-progress[data-progress="60"] .circle .fill.fix {
  transform: rotate(216deg);
}

.radial-progress[data-progress="60"] .inset .percentage .numbers {
  width: 3477px;
}

.radial-progress[data-progress="80"] .circle .mask.full,
.radial-progress[data-progress="80"] .circle .fill {
  transform: rotate(144deg);
}

.radial-progress[data-progress="80"] .circle .fill.fix {
  transform: rotate(288deg);
}

.radial-progress[data-progress="80"] .inset .percentage .numbers {
  width: 4617px;
}

.radial-progress[data-progress="100"] .circle .mask.full,
.radial-progress[data-progress="100"] .circle .fill {
  transform: rotate(180deg);
}

.radial-progress[data-progress="100"] .circle .fill.fix {
  transform: rotate(360deg);
}

.radial-progress[data-progress="100"] .inset .percentage .numbers {
  width: 5757px;
}

.rrethi-vogel {

  li {
    position: absolute;
    width: 70px;
    height: 70px;
    background: rgba(255, 255, 255, 1);
    border: 3px solid #f4f4f4;
    border-radius: 50%;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    transition: all 500ms;

    &.active {
      border-color: $orange;
    }

    &:hover {
      a {
        cursor: pointer;

      }
    }

    .circles {
      color: #000;
      font-weight: 600;
      padding: 0.75rem;
      border: none;

      h6 {
        text-transform: uppercase;
        /* pointer-events: none; */
        color: #bbb;
      }

      &:hover {
        color: $dark;
        h6 {
          color: $dark;
          transition: color .5s linear;
        }
      }

      &.active {
        h6 {
          color: $dark;
        }
      }

    }

  }
}

#circle-tabContent {
  margin-top: 130px;

  h3 {
    max-width: 250px;
    margin: 0 auto;
  }

  p {
    max-width: 300px;
    margin: 20px auto 0;
  }
  .fade {
    transition: opacity 0.5s linear;
  }
}

.rrethi-bullets {
  position: absolute;
  text-align: center;
  z-index: 10;
  right: 50px;
  top: 53%;
  transform: translate3d(0, -50%, 0);

  ul {
    display: block;
    list-style: none;
    li {
      width: 6px;
      height: 6px;
      margin: 34px 0px;

      &.active {
        background: $orange;
        border-radius: 50%;

        a {
          border: 2px solid $orange;
          padding: 15px;
          border-radius: 50%;
          position: absolute;
          margin: -14px -14px;
          opacity: 1;
          background: transparent;
        }
      }

      a {
        margin: 34px 0;
        display: block;
        width: 6px;
        height: 6px;
        background: #000;
        opacity: .2;
        outline: none;
        border: none;
        border-radius: 50%;


      }


    }
  }
}

