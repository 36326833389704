.m30 {
	div {
		text-align: center;
	}
	h2 {
		margin: 0;
		word-wrap: break-word;
	}
	img {
		margin-top: 50px;
		margin-bottom: 30px;
	}
	p {
		margin-bottom: 25px;
	}
	.box {
		border: 2px solid $orange;
		padding: 70px 0;
		@media (max-width: $grid-float-breakpoint-max) {
			padding: 30px 0;
		}
	}
	a {
		text-transform: uppercase;
		border: none;
		letter-spacing: 1.5px;
		font-weight: $medium;
		&:after {
			content: "";
			display: inline-block;
			background-image: url(./images/btn-arrow.svg);
			background-repeat: no-repeat;
			width: 10px;
			height: 10px;
			margin-left: 8px;
		}
		&:hover {
			color: $orange;
		}
	}
	.no-padding {
		padding: 0;
		margin: 0;
	}
}