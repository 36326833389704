.m22 {
  h2 {
    font-family: $rajdhani;
    font-weight: $bold;
    font-size: 60px;
    line-height: 70px;
    letter-spacing: 1px;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 30px;
      line-height: 35px;
    }
  }
  .m22-title {
    /*
    margin-right: auto;
    margin-left: auto;
    max-width: 740px;
    */
    color: $orange;
    margin-bottom: 57px;
    /*
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bttom: 22px;
      padding-right: 40px;
      padding-left: 40px;
    }
    */
  }

  .m22-item-box {
    display: table;
    margin-bottom: 30px;
    padding-top: 5px;
    padding-left: 30px;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      padding-left: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .m22-item-ico {
    @media (min-width: $grid-float-breakpoint) {
      float: left;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 20px;
      text-align: center;

    }
  }

  .m22-content {
    ul {
      list-style: none;
      padding-left: 13px;

      li {
        @extend p.light16;
        margin-bottom: 10px;

        &:before {
          content: " ";
          background: $orange;
          height: 4px;
          width: 4px;
          display: inline-block;
          border-radius: 50%;
          margin: 0px 9px 6px -12px;
        }
      }
    }
  }

  .shadow-small {
    padding: 30px 40px 5px 40px;
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 22px 0 20px 0;
    }

    a {
      display: block;
      border: none;
      padding-bottom: 28px;
      text-decoration: none;

      span {
        border-bottom: 2px solid $orange;
      }

    }
  }

  .sub-title {
    margin-bottom: 22px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 14px;
      width: 80%;

    }
  }

  .sub-text {
    margin-bottom: 50px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 30px;

    }
  }

  .title-box-lg {
    margin-bottom: 25px;
    text-transform: uppercase;
    @media (max-width: $grid-float-breakpoint-max) {
      display: none;
    }
  }

  .title-box-sm {
    display: none;
    @media (max-width: $grid-float-breakpoint-max) {
      display: block;
    }
  }

  .box-sub-title {
    color: $orange;
    margin-bottom: 15px;
  }

  .m22-person {
    color: $orange;
    font-weight: 400;
    margin-top: 33px;
    margin-bottom: 0px;
  }

  .m22-contact-cta {
    border-bottom: none;
    font-weight: $medium;

    &:after {
      content: " ";
      display: inline-block;
      background-image: url(./images/btn-arrow.svg);
      background-repeat: no-repeat;
      width: 9px;
      height: 9px;
      margin-left: 8px;
    }
  }

  .collapse {
    &.dont-collapse-lg {
      @media (min-width: $grid-float-breakpoint) {
        display: block;
        height: auto !important;
        visibility: visible;
      }
    }
  }


  .dont-collapse-lg {
    @media (max-width: $grid-float-breakpoint-max) {
      margin: 20px;
    }
  }

  .more-info {
    @media (min-width: $grid-float-breakpoint) {
      display: none;
    }
    text-align: left;
    margin: 0 auto;

    .close-panel {
      display: none;
    }

    .open-panel {
      display: block;
    }

    a {
      text-decoration: none;
      padding: 0 !important;
      font-size: 14px!important;

      &:after {
        @media (max-width: $grid-float-breakpoint-max) {
          content: "";
          display: inline-block;
          background-image: url(./images/arrow-down.svg);
          background-repeat: no-repeat;
          width: 9px;
          height: 9px;
          margin-left: 5px;
        }
      }
    }

    a[aria-expanded="true"] {
      .close-panel {
        display: block;
      }

      .open-panel {
        display: none;
      }

      &:after {
        @media (max-width: $grid-float-breakpoint-max) {
          content: "";
          display: inline-block;
          background-image: url(./images/arrow-up.svg);
          background-repeat: no-repeat;
          width: 9px;
          height: 9px;
          margin-left: 5px;

        }
      }

    }
  }
  .d-none {
    display: none!important;
  }

}
