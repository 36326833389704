.m21 {
  padding: 100px 0px 100px 0px;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 80px 0px 0px 0px;
  }

  video {
    object-fit: cover;
    height: auto;
  }  
  p {
    margin: 0;
    padding: 0;
    &:first-child {
      margin-bottom: 40px;
    }
  }
  img {
    position: relative;
    z-index: 1;
  }
  h1 {
    position: relative;
    z-index: 2;
  }
  .margin-no {
    margin: 0;
  }
  .regular20 {
    @media screen and (min-width: 992px) and (max-width: 1555px) {
      font-size: 16px;
      line-height: 25px;
    }
  }
  .container {
    &.cm21 {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px;
        margin: 0;
      }

      .m21-left {
        padding: 0px;
        @media (max-width: $grid-float-breakpoint-max) {
          padding: 0px;
        }
      }
    }
  }

  .m21-header-left {
    position: relative;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      box-shadow: 1px 1px 10px $grey-dark;
      z-index: 2;
    }

    .dv-header {
      @media (max-width: $grid-float-breakpoint-max) {
        height: 330px;
        &.dv-header-video{
          height: initial;
          video {
            margin-bottom: -7px;
          } 
         }  
      }

      img {
        @media (max-width: $grid-float-breakpoint-max) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }


    h1 {
      color: $white;
      position: absolute;
      top: 60px;
      left: 50px;
      max-width: 600px;

      @media (max-width: $grid-float-breakpoint-max) {
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;

      }

    }

    .m21-logo {
      position: absolute;
      right: 0px;
      bottom: 0px;
      margin:0px;
      padding:0px;
      box-shadow: 1px 1px 10px $grey-dark;
      @media (max-width: $grid-float-breakpoint-max) {
        position: inherit;
        box-shadow: none;
      }
    }
  }

  .m21-text {
    padding-left: 0;
    padding-right: 0;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: left;
      background: $grey-light;
      padding: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 30px;
    }

    p {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px;
      }
    }
  }
  .orange-border {
    /*
    @-moz-document url-prefix() {
      margin-bottom: 19%;
      @media screen and (min-width: 992px) and (max-width: 1405px) {
        margin-bottom: 9%;
      }
    }
    */
    border: 2px solid $orange;
    border-left: none;
    padding: 9.7% 15% 9.7% 21%;
    @media screen and (min-width: 992px) and (max-width: 1340px) {
      padding: 5% 5% 8% 8%
    }
    @media screen and (max-width: $mobile-breakpoint) {
      border-left: 2px solid $orange;
      padding: 15px;
      border-top: none;
    }
  }
}