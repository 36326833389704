.m14 {
  .m14-title {
    color: $orange;
    @media (min-width: 992px) and (max-width: 1100px) {
      word-break: break-word;
    }
  }
  .pollbar {
    &.organe {
      background: $orange;
    }
  }

  .wp-polls-ul {
    margin-top: -20px;

    @media (max-width: $grid-float-breakpoint-max) {
      padding: 10px 0px 0px 0px;
    }
    li {
      font-weight: $light;
      font-size: 14px;
      line-height: 20px;
      margin: 15px 0px;
      display: block;


      input {
        float: left;
        margin-top: 4px;
      }
      label {
        font-weight: $light;
        font-size: 14px;
        line-height: 20px;
        display: block;
        padding-left: 35px;
        padding-bottom: 5px;
        @media screen and (max-width: $mobile-breakpoint) {
          font-size: 14px;
          padding-left: 30px;
        }
      }
    }

    @keyframes slideInFromLeft {
      0% { width: 0; }
    }

    .pollbar {
      margin-top: 10px;
      animation-name: slideInFromLeft;
      animation-duration: 2s;
    }


    .results {
      padding-left: 15px;
      font-size: 16px;
      font-weight: 400;
    }

  }
.p-submit {
  text-align: left;
  @media (max-width: $grid-float-breakpoint-max) {
    text-align: center;
  }
}
  .btn-vote {
    background: white;
    border-radius: 0;
    box-shadow: 0px 0px 13px $grey-dark;
    border-color: white;
    padding: 19px 50px;
    font-size: 14px;
    margin-top: 30px;
    @media (min-width: $grid-float-breakpoint) {
      margin-left: 0px;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 19px 30px;
    }


    &:after {
      content: "";
      display: inline-block;
      background-image: url(./images/btn-arrow.svg);
      background-repeat: no-repeat;
      width: 9px;
      height: 9px;
      margin-left: 10px;
      float: right;
      @-moz-document url-prefix() {
        float: initial;
      }
      margin-top: 7px;
    }
  }



    ul {
    margin: 0;
    padding: 0;
    li{
      position: relative;
      font-weight: $light;
      font-size: 16px;
      line-height: 25px;
      display: block;
      float: left;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
      }
      label {
        padding: 0;
        @media screen and (max-width: $mobile-breakpoint) {
          padding: 0;
        }        
        cursor: pointer;
        -webkit-transition: all 0.25s linear;
        z-index: 9;
      }
      input[type=radio]{
        visibility: hidden;
      }
      .check{
        display: block;
        border: 1px solid #cccccc;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        position: absolute;
        top: 0px;
        z-index: -2;

      }
    }
  }

  ul li:hover label{
    color: #1f1f1f;
  }
  ul li:hover .check {
    border: 1px solid #cccccc;
  }

  ul li .check::before {
    display: block;
    content: '';
    border-radius: 100%;
    height: 12px;
    width: 12px;
    margin: auto;
    margin-top: 3px;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;

  }

  input[type=radio]:checked ~ .check {
    border: 1px solid #cccccc;
  }

  input[type=radio]:checked ~ .check::before{
    background: $orange;
  }

  input[type=radio]:checked ~ label{
    color: #1f1f1f;
  }



}