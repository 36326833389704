// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from sass/_libraries/bootstrap/_variables.scss, paste it here and edit the value.


$primary: #E67800; // Thats a sample how you could change a BootStrap variable.

$page-break: 992px;
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $page-break;

//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($page-break - 1);

$grid-breakpoints-lg: 992px;
$grid-breakpoints-xl: 1200px;

$mobile-breakpoint: 991px;

//** Container width
$container-max-widths: (
        md: 991px,
        lg: 1199px,
        xl: 1920px
);

$grid-breakpoints: (
        xs: 0,
        lg: 992px,
        xl: 1200px
);

