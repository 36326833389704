.m34 {
  padding: 0;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 80px 0 0 0;
  }
  @supports (-webkit-touch-callout: none) {
    [data-plyr='fullscreen'] {
      display: none;
    }
  }
  .swiper-container {
    height: calc(100% - 95px)!important;
    @media screen and (min-width: 992px) {
      height: calc(100% - 95px)!important;
    }
  }
  p {
    margin: 0;
    padding: 0;
    &:first-child {
      margin-bottom: 40px;
      @media screen and (min-width: 992px) and (max-width: 1100px) {
        margin-bottom: 10px;
      }
    }
  }
  .regular20 {
    @media screen and (min-width: 992px) and (max-width: 1387px) {
      font-size: 16px;
      line-height: 25px;
    }
  }
  img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.image-margin-left {
      padding-left: 13.33333%;
      @media (max-width: $grid-float-breakpoint-max) {
        padding-left: 0;
      }
    }
  }
  video {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &.image-margin-left {
      padding-left: 13.33333%;
      @media (max-width: $grid-float-breakpoint-max) {
        padding-left: 0;
      }
    }
  }
  video::-webkit-media-controls-timeline {
    display: none;
  }
  video::-webkit-media-controls-current-time-display {
   display: none;
  }
  video::-webkit-media-controls-time-remaining-display {
    display: none;
  }
  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
  video::-webkit-media-controls-play-button {
    right: 0
  }
  
  h1 {
    position: relative;
    z-index: 2;
  }
  .margin-no {
    margin: 0;
  }
  .container {
    &.cm7 {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px;
        margin: 0;
      }

      .m7-left {
        padding: 0px;
        @media (max-width: $grid-float-breakpoint-max) {
          padding: 0px;
        }
      }
    }
  }

  .m7-header-left {
    width: 100%;
    position: relative;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      z-index: 2;
    }

    .dv-header {
      @media (max-width: $grid-float-breakpoint-max) {
        height: 330px;
      }

      img {
        @media (max-width: $grid-float-breakpoint-max) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .text-box {
      color: $white;
      position: absolute;

      margin-left: 8.33333%;
      padding-left: 15px;

      z-index: 999;
      @media (min-width: $grid-float-breakpoint-max + 1) {
        bottom:10%;
        h1 {
          font-size: 55px;
          line-height: 55px;

          &:first-child {
            padding-bottom: 15px;
          }
        }
      }
      &.transparent-background {
        background: rgba(246,246,249, 0.7);
        padding: 6% 6% 5% 6%;
        margin: 0;
        top: 30%;
        @media (max-width: $grid-float-breakpoint-max) {
          top: 20%;
        }
        h1 {
          &:first-child {
            color: $orange;
          }
          &:last-child {
            color: $black;
          }
        }
      }

      .invert {
        background-color: $orange;
        box-shadow: 0.2em 0 0 $orange,-0.2em 0 0 $orange;
        color: $white;
        margin-left: 0.2em;
        width: fit-content;
        @media screen and (max-width: $mobile-breakpoint) {
          margin: 10px auto 20px;
        }
      }

      @media (max-width: $grid-float-breakpoint-max) {
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
      }
    }

    .m7-logo {
      position: absolute;
      right: 0px;
      bottom: 0px;
      margin:0px;
      padding:0px;
      box-shadow: 1px 1px 10px $grey-dark;
      @media (max-width: $grid-float-breakpoint-max) {
        position: inherit;
        box-shadow: none;
      }
    }
  }

  .m7-text {
    padding-left: 0;
    padding-right: 0;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: left;
      background: $grey-light;
      padding: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 25px;
    }

    p {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px;
      }
    }
  }
  .orange-border {
    border: 2px solid $orange;
    border-left: none;
    padding: 9.7% 15% 9.7% 21%;
    @media screen and (min-width: 993px) and (max-width: 1403px) {
      padding: 7% 11% 7% 15%;
    }
    @media screen and (max-width: $mobile-breakpoint) {
      border-left: 2px solid $orange;
      padding: 50px 15px 15px 15px;
      border-top: none;
    }
  }
  .swiper-pagination-bullets {
    position: relative;
    text-align: center;
    z-index: 10;
    margin-top: -50px;
    margin-bottom: 50px;
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 10px 0;
    }

    .swiper-pagination-bullet {
      @media (max-width: $grid-float-breakpoint-max) {
        margin: 0 9px;
        width: 6px;
        height: 6px;
        background: $orange;
        opacity: 1;
        outline: none;
      }
      @media (min-width: $grid-float-breakpoint) {
        margin: 0 15px;
        display: inline-block;
        width: 10px;
        height: 10px;
        background: #fff;
        opacity: 1;
        outline: none;
      }

    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background: #fff;
      @media screen and (max-width: $mobile-breakpoint) {
        background: $orange;
      }

      &:focus {
        outline: none;
      }

      p {
        border: 2px solid #fff;
        padding: 13px;
        border-radius: 50%;
        position: absolute;
        margin: -10px -10px;
        line-height: 0;

        @media screen and (max-width: $mobile-breakpoint) {
          border: 1px solid $orange;
          padding: 8px;
          margin: -6px -6px;
        }

        &:focus {
          outline: none;
        }

      }

    }
  }
  .img-op {
    -webkit-filter: brightness(70%);
    filter: brightness(70%);
  }
  .butoni {
    display: inline-grid;
    margin: 0;
    padding: 0;
    background: white;
    border-radius: 0;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-color: white;
    margin-top: 2rem;

    a {
      &:hover {
        color: $black;
      }
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding: 15px 50px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-left: 3px;
      margin-top: 1rem;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 15px 30px;
      }
    }
    /*
    a {
    @media screen and (min-width: 992px) {
        &:before {
          content: '';
          position: absolute;
          border-bottom: 2px solid $bondi-blue;
          width: 42%;
          height: 15px;
          left: -42%;
        }
      }
    }
    */
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 0 auto;
    }
    img {
      width: 5px;
      margin: 0px;
      margin-left: 4px;
      @media screen and (max-width: $mobile-breakpoint) {
        width: initial!important;
        height: initial!important;
        object-fit: initial!important;
      }

    }
    .bttn {
      font-size: 14px;
      letter-spacing: 1.5px;
      line-height: 30px;
      padding: 15px 50px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 0;
      }
      margin: 0;
      border: 0;
      text-transform: uppercase;
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 8px 17px;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          padding: 0;
        }
        font-size: 12px;
        letter-spacing: 1.3px;
      }
    }
  }
  .m7-img {
    filter: brightness(85%);
  }

  .video-slide {
    .swiper-container {
      height: initial!important;
      .dv-header {
        height: 100%;
      }


      .text-box {
        @media (max-width: $grid-float-breakpoint-max) {
          position: initial!important;
          transform: none;
          margin: 20px 0 10px;
          h1 {
            display: none;
          }
    
        }
      }
    }  
  } 
}