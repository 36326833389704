.m2 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m3 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m4 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m5 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m6 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m7 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m8 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m10 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
    text-decoration: underline;
  }
}

.m11 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
    &.small {
      font-size: 14px!important;
      line-height: 20px!important;
      letter-spacing: 1.5px!important;
      border: none!important;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 12px!important;
      }
    }
  }
}

.m12 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m13 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m15 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m16 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
    &.btn {
      font-family: "Roboto", sans-serif!important;
      font-weight: 500!important;
      font-size: 14px!important;
      letter-spacing: 1.3px!important;
      color: #101010!important;
      text-transform: uppercase!important;
    }
  }
}

.m17 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m18 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m20 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m21 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m22 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
    &.small {
      font-weight: 500!important;
      font-size: 14px!important;
      line-height: 20px!important;
      letter-spacing: 1.5px!important;
      border: none!important;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 12px!important;
      }
    }
  }
}

.m24 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m25 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m26 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m27 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m28 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m29 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m30 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m31 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
    border-bottom: 2px solid #e67800;
  }
  .contact_mail_tel {
    a {
      border-bottom: none!important;
    }
  }
}

.m32 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
  }
}

.m33 {
  a {
    font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit!important;
    line-height: inherit!important;
    color: inherit!important;
    font-family: inherit!important;
    border-bottom: 2px solid #e67800;
  }
}