.site-header-short {
  transition: all .3s linear;

  &.short {
    nav {
      @media (min-width: $grid-float-breakpoint) {
        height: 152px;
      }
    }
  }
  .site-title {

    float: left;
    margin: 5px 0;
    padding: 0;

    @media (max-width: $grid-float-breakpoint-max) {
      float: none;
      margin: 0px;
    }

    > a {
      display: block;
      width: 205px;
      height: 50px;
      opacity: 1;
      text-indent: -9999px;
      margin: 0px;
      background-repeat: no-repeat;
      border: none;

      @media (max-width: $grid-float-breakpoint-max) {
        width: 100px;
        height: 24px;
        margin-left: 20px!important;
        background-size: 100%;
        margin-top: 18px;
        margin-bottom: 18px;

      }
    }
  }

  .navbar {
    background: #FFF;
    border-bottom: 1px solid #E4E4E4;
  }
  &.scrolled {
    &.short {
      nav {
        @media (min-width: $grid-float-breakpoint) {
          height: 71px;
        }
      }
    }
    .navbar {
      background: #FFF;
      border-bottom: none;
      padding-top: 0px;
      padding-bottom: 0px;
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 2px 0px 2px 0px;
      }

      .site-title {
        @media (min-width: $grid-float-breakpoint) {
          width: 205px;
          transition: width .3s linear;

        }
        a {
          @media (min-width: $grid-float-breakpoint) {
            width: 165px;
            height: 40px;
            background-size: 100%;
            transition: all .3s linear;
          }
        }
      }

    }
    .site-title {
      > a {
        @media (min-width: $grid-float-breakpoint) {
          margin: 0px;
        }
      }
    }
  }

  .site-description {
    display: none;
    visibility: hidden;
  }
  .noborder {
    border: none;
  }
}
