.hbspt-form {
    fieldset {
        max-width: 100%!important;
        @media (max-width: 991px) {
            margin: 0 auto!important;
        }
    }
    p {
        line-height: initial!important;
        font-size: 9px!important;
        a {
            font-size: 9px!important;
            display: inline-block !important;
            line-height: initial;
            font-weight: 300!important;
        }
    }
    .field {
        margin-bottom: 16px;
        .input {
            //margin: 0!important;
        } 
        .hs-input {
            @media (max-width: 991px) {
                //width: 100%!important;
            }
        }
        width: 100%!important;
        input {            
            font-size: 14px;
            padding: 0 15px;
        }
        &.hs-fieldtype-text,
        &.hs-phone {
            input {
                height: 40px;
                width: 100%;
                font-size: 16px;
                line-height: 22px;
                color: #33475b;
                border: 1px solid #cbd6e2;
                box-sizing: border-box;
                border-radius: 3px;
                background-color: #f5f8fa !important;
                &:focus-visible{
                    border-color: initial !important;
                    outline: none;
                    border-color: rgba(82, 168, 236, .8)!important;
                } 
            }
            label {
                display: none;
            }
        }
        
        &.hs-fieldtype-booleancheckbox {
            padding: 0!important;
            margin: 0!important;
        }
        
    }
    .hs-fieldtype-select {
        label {
            display: none!important;
        }
        select {
            padding: 0 15px;
            height: 40px;
            width: 100%;
            font-size: 16px;
            line-height: 22px;
            color: #33475b;
            border: 1px solid #cbd6e2;
            box-sizing: border-box;
            border-radius: 3px;
            background-color: #f5f8fa !important;
            &:focus-visible{
                border-color: initial !important;
                outline: none;
                border-color: rgba(82, 168, 236, .8)!important;
            }
        }
     }
    .form-columns-2 {
        .hs-form-field {
            width: 50%!important;
            //margin-right: 0!important;
            @media (max-width: 991px) {
                width: 100%!important;
                div {
                    margin: 0!important;
                }
            }
            .hs-input {
                @media (max-width: 991px) {
                    width: 100%!important;
                }
            }
        }
    }
    .hs-button {
        display: inline-block;
        background-color: #e67800;
        color: #ffffff;
        border-radius: 0;
        padding: 10px 17px;
        font-family: $roboto;
        font-size: 18px;
        border: 0;
        cursor: pointer;
        max-width: 100%;
        line-height: 40px;
        font-weight: bold;
        min-width: 260px;
    }
    ul {
        list-style-type: none;
        padding: 0!important;
        margin: 0!important;
        li {
            font-size: initial!important;
            line-height: initial!important;
            padding-bottom: initial!important;
            margin-bottom: 2px;
            label {
                padding: 0!important;
                margin: 0!important;
            }
            &.hs-form-booleancheckbox {
                cursor: pointer;
                label {
                    cursor: pointer;
                }
            }
        } 
    }
    label {
        font-size: 9px!important;
        span p {
            font-size: 9px!important;
        }
    }
    input[type="radio"], input[type="checkbox"] {
        //margin-top: 8px;
        cursor: pointer;
    }

    .hs-richtext {
        p {
            line-height: 1.4 !important;
        }
    }
    .hs-submit {
        text-align: right;
        margin-right: 8px;
        @media (max-width: 991px) {
            margin: 0 auto!important;
            text-align: center;
        }
    }
}