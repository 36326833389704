.m41 {
  .light25 {
    margin: 0;
  }
  .light16 {
   margin-top: 18px;
    @media (max-width: 991px) {
      margin-top: 9px;
      margin-bottom: 20px;
    }
  }
}
.desktop-only {

  @media (max-width: $mobile-breakpoint) {
    display: none;
  }
}

.mobile-only {
  display: none!important;
  @media (max-width: $mobile-breakpoint) {
    display: block!important;
  }
}
