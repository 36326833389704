.m23 {
	margin: 0px 12px;
	@media screen and (max-width: $mobile-breakpoint) {
		margin: 0;
	}
	.row {
		margin: 0;
	} 
	.ihransprechpartner {
		h2 {
			margin-bottom: 38px;
		}
		h2 {
			@media screen and (max-width: $mobile-breakpoint) {
				margin-bottom: 28px;
			}
		}
		a {
			text-transform: uppercase;
			border: none;
			letter-spacing: 1.5px;
			font-weight: $medium;
			&.small-light {
				font-size: 14px;
				font-weight: $light;
				letter-spacing: 0.61px;
				line-height: 25px;
				@media screen and (max-width: $mobile-breakpoint) {
					font-size: 14px;
					font-weight: $light;
					letter-spacing: 0.22px;
					line-height: 20px;

				}
			}
			&:after {
				content: "";
				display: inline-block;
				background-image: url(./images/btn-arrow.svg);
				background-repeat: no-repeat;
				width: 10px;
				height: 10px;
				margin-left: 8px;
			}
			&:hover {
				color: $orange;
			}
		}
		.pdf-download {
			font-weight: $medium;
			&:after {
				background-image: url(./images/pdf_download.svg);
				width: 21px;
				height: 22px;
			}
		}
		.box {
			background: $white;
			padding: 30px 66px 66px 66px;
			border: solid 1px $grey-light;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
			-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
			-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
			-o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
			@media screen and (min-width: 992px) and (max-width: 1445px) {
				padding: 30px 28px;
			}
			@media screen and (max-width: $mobile-breakpoint) {
				padding: 30px 28px;
			}
			@media screen and (max-width: 350px) {
				padding: 20px 10px;
			}
			img {
				border-radius: 50%;
				width:100px;
				@media screen and (max-width: $mobile-breakpoint) {
					margin-bottom: 28px;
					text-align: center;
				}
			}
			h2 {
				text-align: center;
			}
			p {
				margin: 0;
				&.tel {
					margin-top: 15px;
					margin-bottom: 5px;
				}
			}
			.row {
				margin: 0;
			}
			.premedia_info {
				@media screen and (max-width: $mobile-breakpoint) {
				margin-top: 24px;
			}
			}
			.person_image {
				text-align: center
			}
		}
		.box > div {
			@media screen and (max-width: $mobile-breakpoint) {
				padding: 0!important;
				margin: 0!important;
			}
		}
	}
	span {
		font-weight: $regular;
		margin-right: 18px;
	}
	.smoothscroll {
		cursor: pointer;
	}

}
#page-2351.m23 a.small.pdf-download.smoothscroll {
	display: none;
}
.m23 p.light16 a {
	font-size: 14px!important;
	text-transform: uppercase!important;
	border: none!important;
	letter-spacing: 1.5px!important;
	font-weight: 500!important;
}


.m23 p.light16 a {
	font-size: 14px!important;
	text-transform: uppercase!important;
	border: none!important;
	letter-spacing: 1.5px!important;
	font-weight: 500!important;
}