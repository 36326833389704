.m19 {
	text-align: center;
	a {
		border: none;
	}
	.box {
		background: #ffffff;
		padding: 55px 100px;
		border: solid 1px #f6f6f6;
		box-shadow: 0 0 10px rgba(0, 0, 0, .2);
		@media screen and (max-width: $mobile-breakpoint) {
			padding: 40px 15px;
		}
	}
	.medium14 {
		text-transform: uppercase;
		letter-spacing: 1.5px;
		margin-bottom: 55px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 40px;
		}
	}
	img {
		width: 100%;
	}
	.icon-box {
		width: 16.6%;
		&.rows-per-2 {
			@media screen and (min-width: $mobile-breakpoint + 1) {
				width: 20.1%;
			}
		}

		@media screen and (min-width: 1331px) and (max-width: 1539px) {
			margin: 0 3%;
			margin-bottom: 60px;
		}
		@media screen and (min-width: 1206px) and (max-width: 1330px) {
			margin: 0 3%;
			margin-bottom: 60px;
		}
		@media screen and (min-width: 993px) and (max-width: 1205px) {
			margin: 0 7%;
			margin-bottom: 60px;
		}
		@media screen and (max-width: $mobile-breakpoint) {
			width: 50%;
			margin-bottom: 0;
		}
		@media screen and (max-width: 374px) {
			width: 100%;
		}
	}
	.round_icon {
		position: relative;
		background: $white;
		border: none;
		border-radius: 50%;
		width: 150px;
		margin: 0 auto;
		margin-bottom: 25px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 24px;
		}
	}
	.clearfix {
		height: 100px;
		@media screen and (max-width: $mobile-breakpoint) {
			height: 0;
		}
		&:last-child {
			height: 0;
		}
	}
	.first-title {
		margin-bottom: 10px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 5px;
		}
	}
	.second-title {
		margin-top: 55px;
		margin-bottom: 40px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 25px;
			margin-top: 40px;
		}
	}
}