.footer-cta {
  min-height: 90px;
  background: $grey-light;
  border-bottom: 1px solid $grey-medium;
  @media (min-width: $grid-float-breakpoint) {
    border-bottom: 1px solid $grey-dark;
  }

  .container {
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0px;
    }
  }

  .footer-cta-left {
    @media (min-width: $grid-float-breakpoint) {
      float: left;
    }

    a:last-child {
      @media (min-width: $grid-float-breakpoint) {
        margin-left: 65px;
      }
      @media (max-width: $grid-float-breakpoint-max) {
        display: block;
        margin-bottom: 30px;
      }
      @media (min-width: 992px) and (max-width: 1350px){
        margin-left: 20px;
      }

    }
  }

  .footer-cta-right {
    a {
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 0px;
        width: 49%;
        padding: 35px 0px 35px;

      }
    }

    p {
      display: inline;
    }

    @media (min-width: $grid-float-breakpoint) {
      float: right;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      border-top: 1px solid $grey-medium;

    }

    .cta-linkedin {
      @media (min-width: $grid-float-breakpoint) {
        float: left;
        margin-right: 50px;
      }
      @media (max-width: $grid-float-breakpoint-max) {
        display: inline-block;
        padding-right: 10px;
      }
      @media (min-width: 992px) and (max-width: 1350px){
        margin-right: 20px;
      }
    }

    .cta-xing {
      @media (min-width: $grid-float-breakpoint) {
        float: left;
        margin-right: 50px;
      }
      @media (max-width: $grid-float-breakpoint-max) {
        display: inline-block;
        border-left: 1px solid $grey-medium;
        border-right: 1px solid $grey-medium;
        padding: 0px 20px;
      }
      @media (min-width: 992px) and (max-width: 1300px){
        margin-right: 20px;
      }
    }

    .cta-facebook {
      @media (min-width: $grid-float-breakpoint) {
        float: right;
      }
      @media (max-width: $grid-float-breakpoint-max) {
        display: inline-block;
        padding-left: 10px;
      }
      /*
      a {
        @media (max-width: $grid-float-breakpoint-max) {
          border-left: 1px solid $grey-medium;
        }
      }
      */
    }
  }


  a {
    margin-top: 35px;
    display: inline-table;
    line-height: 24px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 10px;
    }

    img {
      @media (min-width: $grid-float-breakpoint) {
        padding-right: 15px;
      }
      @media (max-width: $grid-float-breakpoint-max) {
        display: block;
        margin: 25px auto 13px;
      }
    }

    &:after {
      content: "";
      display: inline-block;
      background-image: url(./images/btn-arrow.svg);
      background-repeat: no-repeat;
      width: 9px;
      height: 9px;
      margin-left: 8px;
    }
  }

  .language-change {
    @media (min-width: $grid-float-breakpoint) {
      position: absolute;
      left: 45.5%;
    }
    @media (max-width: $grid-float-breakpoint) {
      border-top: 1px solid #e4e4e4;
      padding-bottom: 7px;
    }
    @media (min-width: 1400px) and (max-width: 1600px){
      left: 52%;
    }
    @media (min-width: 992px) and (max-width: 1399px){
      left: 54%;
    }

    a {
      font-family: $roboto;
      font-size: 14px;
      line-height: 25px;
      border: 0;
      color: #101010;

      &.active {
        color: #E67800;
      }

      &:after {
        display: none;
        cursor: initial;
      }

      @media (min-width: $grid-float-breakpoint) {
        &:first-child {
          margin-right: 3px;
        }
        &:last-child {
          margin-left: 8px;
        }
      }
    }

    .language-seperator {
      display: inline-block;
      margin-top: 35px;
      padding: 0 4px;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 10px;
      }
      @media (min-width: $grid-float-breakpoint) {
        position: absolute;
        padding: 0;
        margin: 0;
        top: 35px;
      }
    }
  }
}


.footer-contents {
  background: $grey-light;

  .ainitial {
    a {
      border: 0;
    }
  }

  .city_space {
    width: 90px;
    display: inline-block;
    font-weight: 300;
  }

  .container {
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0px;
    }
  }

  .footer-content-left {
    @media (min-width: $grid-float-breakpoint) {
      padding-top: 64px;
      padding-bottom: 22px;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      padding-top: 27px;
    }
    
    @media (min-width: $grid-float-breakpoint) {
      min-height: 340px;
      text-align: left;


    }

    .footer-col-logo-1 {
      @media (max-width: $grid-float-breakpoint-max) {
        border-top: 1px solid $grey-medium;
        .light16 {
          max-width: 500px;
          margin: 0 auto;
          padding: 0 15px;
          padding-bottom: 50px;
        }
      }

      img {
        @media (min-width: $grid-float-breakpoint) {
          margin: 80px auto 56px auto;
        }
        @media (max-width: $grid-float-breakpoint-max) {
          margin: 27px auto 37px auto;
        }
      }
    }

    .footer-col-logo {
      text-align: center;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 25px;
      }

      img {
        max-width: 130px;
        max-height: 32px;
        @media (min-width: $grid-float-breakpoint) and (max-width: 1100px) {
          max-width: 90%;
        }
      }
    }
  }

  .footer-content-right {
    @media (min-width: $grid-float-breakpoint) {
      min-height: 340px;
      border-left: 1px solid $grey-dark;
    }

    @media (min-width: $grid-float-breakpoint) {
      padding-top: 64px;
      padding-bottom: 22px;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      padding-top: 27px;
    }

    .all-locations {
      padding-bottom: 15px;
    }

    @media (max-width: 991px) {
      .all-locations {
        text-align: left;
        min-width: 300px;
        display: inline-block;
        padding-bottom: 0;
        margin: 0 15px;
    }
    }

    .bullet {
      margin-top: 17px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 19px;
      display: block;
      width: 4px;
      height: 4px;
      background: $orange;
      outline: none;
      border-radius: 100%;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-top: 16px;
        margin-bottom: 10px;
      }
    }

    .address1 {
      @media (max-width: $grid-float-breakpoint-max) {
        border-bottom: 1px solid $grey-medium;
      }
    }

  }


  p {
    &:last-child {
      @media (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 28px;
      }
    }

    span {
      font-weight: 400;

      &.tel-first {
        margin-right: 15px;

        @media (max-width: $grid-float-breakpoint-max) {
          margin-right: 10px;
        }
      }

      &.tel-second {
        margin-right: 15px;

        @media (max-width: $grid-float-breakpoint-max) {
          margin-right: 10px;
        }


      }


    }

    a {
      &.medium {
        font-weight: 300;
        border-bottom: none;
      }
    }
  }
}

.footer-menu {
  margin-top: 40px;
  z-index: 999;
  position: relative;
  @media (max-width: $grid-float-breakpoint-max) {
    text-align: center;
    margin-top: 20px;
  }

  .footer-privacy {
    padding: 5px 0px 0px;
    background: #101010;

    .footer-global-menu {
      display: block;
      list-style: none;
      padding: 0;
      margin-bottom: 0px;

      > li {
        display: inline-block;
        padding: 0 20px 0 5px;

        @media (max-width: $grid-float-breakpoint-max) {
          display: block;
          padding: 0px 0;
        }

        a {
          font-size: 14px;
          color: #FFF;
          text-decoration: none;
          border: none;
          font-weight: 400;

          &:hover {
            color: $primary;
          }
        }

        &:after {
          content: " ";
          background: white;
          height: 4px;
          width: 4px;
          display: inline-block;
          border-radius: 50%;
          margin: 0px 0px 3px 25px;

          @media (max-width: $grid-float-breakpoint-max) {
            content: " ";
            background: white;
            height: 4px;
            width: 4px;
            display: block;
            border-radius: 50%;
            margin: 0 auto;
          }
        }

        &:last-child {
          &:after {
            content: none;
          }
        }
      }
    }

    .copyright {
      padding-top: 10px;
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 15px 0px 10px 0px;
      }
      color: #FFF;
      font-size: 14px;
    }
  }
}

.location-slider {
  width: 100%;

  span.swiper-pagination-bullet {
    margin: 0 11px !important;
  }

  .swiper-pagination-bullet-active {
    background: $orange;
  }

  .swiper-pagination-bullets {
    bottom: -7px;
    @media (max-width: $mobile-breakpoint) {
      bottom: 5px;
    }
  }

  .no-pagination {
    @media (min-width: 1137px) {
      display: none;
    }
  }
}

.mobile-and-desktop {

  @media (min-width: 992px) and (max-width: 1199px) {
    display: none;
  }
}

.tablet-only {

  @media (min-width: 1200px) {
    display: none !important;
  }
  @media (max-width: 991px) {
    display: none;
  }
}

@media (max-width: 787px) {
  footer {
    .prm-0 {
      padding-right: 0;
    }

    .col-lg-10.col-offset-1.mobile-and-desktop {
      width: calc(100% - 15px);
    }
  }
}