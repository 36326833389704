.m33 {
  padding: 0 0 60px 0;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 80px 0 0 0;
    margin-bottom: 20px;
  }
  p {
    margin: 0;
    padding: 0;
    &:first-child {
      margin-bottom: 40px;
      @media screen and (min-width: 992px) and (max-width: 1100px) {
        margin-bottom: 10px;
      }
    }
  }
  .regular20 {
    @media screen and (min-width: 992px) and (max-width: 1387px) {
      font-size: 16px;
      line-height: 25px;
    }
  }
  img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
    &.image-margin-left {
      padding-left: 13.33333%;
      @media (max-width: $grid-float-breakpoint-max) {
        padding-left: 0;
      }
    }
  }
  h1 {
    position: relative;
    z-index: 2;
  }
  .margin-no {
    margin: 0;
  }
  .container {
    &.cm7 {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px;
        margin: 0;
      }

      .m7-left {
        padding: 0px;
        @media (max-width: $grid-float-breakpoint-max) {
          padding: 0px;
        }
      }
    }
  }

  .m7-header-left {
    width: 100%;
    position: relative;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      box-shadow: 1px 1px 10px $grey-dark;
      z-index: 2;
    }

    .dv-header {
      @media (max-width: $grid-float-breakpoint-max) {
        height: 330px;
      }

      img {
        @media (max-width: $grid-float-breakpoint-max) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .text-box {
      color: $white;
      position: absolute;
      bottom:10%;
      margin-left: 8.33333%;
      padding-left: 15px;

      z-index: 999;
      h1 {
        font-size: 55px;
        line-height: 55px;
        &:first-child {
          padding-bottom: 15px;
        }
      }
      &.transparent-background {
        background: rgba(246,246,249, 0.7);
        padding: 6% 6% 5% 6%;
        margin: 0;
        top: 30%;
        @media (max-width: $grid-float-breakpoint-max) {
          top: 20%;
        }
        h1 {
          &:first-child {
            color: $orange;
          }
          &:last-child {
            color: $black;
          }
        }
      }

      @media (max-width: $grid-float-breakpoint-max) {
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;
      }
    }

    .m7-logo {
      position: absolute;
      right: 0px;
      bottom: 0px;
      margin:0px;
      padding:0px;
      box-shadow: 1px 1px 10px $grey-dark;
      @media (max-width: $grid-float-breakpoint-max) {
        position: inherit;
        box-shadow: none;
      }
    }
  }

  .m7-text {
    padding-left: 0;
    padding-right: 0;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: left;
      background: $grey-light;
      padding: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 25px;
    }

    p {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px;
      }
    }
  }
  .orange-border {
    border: 2px solid $orange;
    border-left: none;
    padding: 9.7% 15% 9.7% 21%;
    @media screen and (min-width: 993px) and (max-width: 1403px) {
      padding: 7% 11% 7% 15%;
    }
    @media screen and (max-width: $mobile-breakpoint) {
      border-left: 2px solid $orange;
      padding: 50px 15px 15px 15px;
      border-top: none;
    }
  }
    .swiper-pagination-bullets {
    position: absolute;
    text-align: center;
    z-index: 10;

    @media (max-width: $grid-float-breakpoint-max) {
      top: 26.5rem;
      left: 0;
      width: 100%;
    }
    @media (min-width: $grid-float-breakpoint) {
      right: 50px;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    .swiper-pagination-bullet {
      @media (max-width: $grid-float-breakpoint-max) {
        margin: 0 20px;
        width: 6px;
        height: 6px;
        background: #000;
        opacity: .2;
        outline: none;
      }
      @media (min-width: $grid-float-breakpoint) {
        margin: 34px 0;
        display: block;
        width: 6px;
        height: 6px;
        background: #fff;
        opacity: .7;
        outline: none;
      }

    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background: $primary;

      &:focus {
        outline: none;
      }

      p {
        border: 2px solid $primary;
        padding: 15px;
        border-radius: 50%;
        position: absolute;
        margin: -14px -14px;
        line-height: 0;

        &:focus {
          outline: none;
        }

      }

    }
  }
  .img-op {
    -webkit-filter: brightness(70%);
    filter: brightness(70%);
  }
  @media screen and (max-width: 1440px){
   .swiper-container {
      max-height: 590px;
    }
  }
}