.m12 {
  .grey-background {
    background: $grey-light;
  }

  .check-padding {
    @media (min-width: $grid-float-breakpoint-max+1) {
      div {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .m12-box {
    min-height: 290px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 10px;
      min-height: 170px;
    }
    h3 {
      padding-top: 60px;
      @media (max-width: $grid-float-breakpoint-max) {
        padding-top: 22px;
      }
      &:after {
        content: " ";
        background: #e67800;
        height: 4px;
        width: 4px;
        display: block;
        border-radius: 50%;
        margin: 20px auto;
      }
    }

    p {
      max-width: 260px;
      margin: 0 auto;
      display: block;
    }
  }
}