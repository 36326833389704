.m40 {
  margin: 0 12px;
  @media screen and (max-width: $mobile-breakpoint) {
    margin: 0;
  }

  .row {
    margin: 0;
  }

  .ihransprechpartner {
    h2 {
      margin-bottom: 38px;
    }

    h2 {
      @media screen and (max-width: $mobile-breakpoint) {
        margin-bottom: 28px;
      }
    }

    .read-article {
      text-transform: uppercase;
      border: none;
      letter-spacing: 1.5px;
      font-weight: $medium;

      &.small-light {
        font-size: 14px;
        font-weight: $light;
        letter-spacing: 0.61px;
        line-height: 25px;
        @media screen and (max-width: $mobile-breakpoint) {
          font-size: 14px;
          font-weight: $light;
          letter-spacing: 0.22px;
          line-height: 20px;

        }
      }

      &:after {
        content: "";
        display: inline-block;
        background-image: url(./images/btn-arrow.svg);
        background-repeat: no-repeat;
        width: 10px;
        height: 10px;
        margin-left: 8px;
      }

      &:hover {
        color: $orange;
      }

    }

    .pdf-download {
      font-weight: $medium;

      &:after {
        background-image: url(./images/pdf_download.svg);
        width: 21px;
        height: 22px;
      }
    }

    .box {
      background: $white;
      padding: 50px 66px 62px 66px;
      border: solid 1px $grey-light;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      @media screen and (min-width: 992px) and (max-width: 1445px) {
        padding: 30px 28px;
      }
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 30px 28px;
      }
      @media screen and (max-width: 350px) {
        padding: 20px 0;
      }

      img {
        border-radius: 50%;
        width: 100px;
        @media screen and (max-width: $mobile-breakpoint) {
          margin-bottom: 28px;
          text-align: center;
        }
      }

      h2 {
        text-align: center;
      }

      h4 {
        @media (max-width: 767px) {
          margin-top: 25px;
        }
      }

      p {
        margin: 0;
        @media screen and (max-width: $mobile-breakpoint) {
          margin-bottom: 5px;
        }

        &.tel {
          margin-top: 15px;
          margin-bottom: 5px;
        }
      }

      .row {
        margin: 0;
      }

      .premedia_info {
        @media screen and (max-width: $mobile-breakpoint) {
          margin-top: 35px;
        }
      }

      .person_image {
        @media (min-width: 992px) {
          position: absolute;
          right: 23px;
          top: 0;
        }
        text-align: center;
        z-index: 99999;

        > img {
          display: block;
          margin-bottom: 8px;
          margin-left: auto;
          margin-right: auto;
        }

        .social-media {
          a {
            border-bottom: none;
            line-height: initial;
            letter-spacing: initial;
            font-size: 23px;
            position: relative;
            margin-left: 6px;

            &:after {
              content: "";
              display: inline-block;
              width: 1px;
              height: 12px;
              background: #CCCCCC;
              position: absolute;
              top: 5px;
              right: 0;
            }

            &:last-child {
              &:after {
                display: none;
              }
            }

            div {
              display: inline-block;
              width: 22px;
              height: 22px;

              &.linkedin {
                background-image: url(./images/linked.svg);

                &:hover {
                  background-image: url(./images/linked_hover.svg);
                }
              }

              &.twitter {
                background-image: url(./images/twitter.svg);

                &:hover {
                  background-image: url(./images/twitter_hover.svg);
                }
              }

              &.mail {
                background-image: url(./images/mail.svg);

                &:hover {
                  background-image: url(./images/mail_hover.svg);
                }
              }
            }
          }
        }
      }
    }

    .box > div {
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }

  span {
    font-weight: $regular;
    margin-right: 18px;
  }

  .smoothscroll {
    cursor: pointer;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
  }

  li {
    position: relative;
    padding-left: 22px;
    font-family: $roboto;
    font-weight: $light;
    font-size: 25px;
    line-height: 40px;
    letter-spacing: 0.3px;
    color: $dark;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 20px;
      line-height: 35px;
      letter-spacing: 0.3px;
    }
    @media (max-width: 991px) {
      margin-bottom: 5px;
    }

    &.regular16 {
      font-weight: $regular;
      font-size: 16px;
      line-height: 25px;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 14px;
        line-height: 20px;
        a {
          font-size: 14px;
        }
      }
    }
  }

  li::before {
    content: "";
    position: absolute;
    background-color: $orange;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    left: 0;
    top: 9px;
  }

}

#page-2351.m23 a.small.pdf-download.smoothscroll {
  display: none;
}

.m23 p.light16 a {
  font-size: 14px !important;
  text-transform: uppercase !important;
  border: none !important;
  letter-spacing: 1.5px !important;
  font-weight: 500 !important;
}


.m23 p.light16 a {
  font-size: 14px !important;
  text-transform: uppercase !important;
  border: none !important;
  letter-spacing: 1.5px !important;
  font-weight: 500 !important;
}