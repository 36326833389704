.m2 {
  .m2-title {
    color: $orange;
  }
  @media (max-width: $mobile-breakpoint) {
    .mbm-5 {
      margin-bottom: 2rem;
    }
  }
  .image-present {
    font-size: 22px;
    line-height: 24px;
  }
  .text-center-mobile {
     @media screen and (max-width: $mobile-breakpoint) {
      text-align: center;
     }
  }

  .m2-content {
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  .butoni {
    display: inline-grid;
    margin: 0;
    padding: 0;
    background: white;
    border-radius: 0;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-color: white;
    margin-top: 2rem;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding: 15px 50px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-left: 3px;
      margin-top: 1rem;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 15px 30px;
      }
    }
    /*
    a {
    @media screen and (min-width: 992px) {
        &:before {
          content: '';
          position: absolute;
          border-bottom: 2px solid #e67800;
          width: 42%;
          height: 15px;
          left: -42%;
        }
      }
    }
    */
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 0 auto;
    }
    img {
      width: 5px;
      margin: 0px;
      margin-left: 4px;
    }
    .bttn {
      font-size: 14px;
      letter-spacing: 1.5px;
      line-height: 30px;
      padding: 15px 50px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 0;
      }
      margin: 0;
      border: 0;
      text-transform: uppercase;
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 15px 30px;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          padding: 0;
        }
        font-size: 12px;
        letter-spacing: 1.3px;
      }
    }
  }
  hr {
    margin: 0;
    height: 1px;
    background: #e67800;
    margin-left: -47%;
    margin-top: -30px;
    width: 70%;
    @media screen and (max-width: $mobile-breakpoint) {
      display: block;
      margin: 0;
      height: 1px;
      background: $orange;
      margin-left: -15px;
      margin-top: -30px;
      width: 50%;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: none;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    padding-left: 1em;
    text-indent: -.7em;
    font-family: $roboto;
    font-weight: $light;
    font-size: 25px;
    line-height: 40px;
    letter-spacing: 0.3px;
    color: $dark;
    @media screen and (min-width: $mobile-breakpoint) {
      margin-bottom: 20px;
    }
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 20px;
      line-height: 35px;
      letter-spacing: 0.3px;
    }
    &.light25 {
      font-weight: $light;
      font-size: 25px;
      line-height: 40px;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 20px;
        line-height: 35px;
      }
    }
  }
  li::before {
    content: "• ";
    color: $orange; /* or whatever color you prefer */
  }
}