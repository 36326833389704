.m28 {
  input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  padding: 100px 0 100px 0;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 80px 0 0 0;
  }
  h3 {
    margin: 0;
    padding: 0;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
    }
  }
  p {
    margin: 0;
    padding: 0;
    &:first-child {
      margin-bottom: 40px;
    }
  }
  img {
    position: relative;
    z-index: 1;
    max-height: 380px;
  }
  .margin-no {
    margin: 0;
  }
  .container {
    &.cm7 {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0;
        margin: 0;
      }

      .m7-left {
        padding: 0;
        @media (max-width: $grid-float-breakpoint-max) {
          padding: 0;
        }
        img {
          @media (max-width: $grid-float-breakpoint-max) {
            padding: 30px;
          }
        }
      }
    }
  }

  .m7-header-left {
    position: relative;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      z-index: 2;
    }
    h1 {
      color: $white;
      position: absolute;
      top: 60px;
      left: 50px;
      max-width: 600px;

      @media (max-width: $grid-float-breakpoint-max) {
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
  form {
    .radio-butonet {
      margin: 0;
    }
    .input-filed {
      position: relative;
      margin-bottom: 25px;
      span {
        font-family: $roboto;
        font-weight: $light;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.33px;
        margin-left: 21px;
        @media (max-width: $grid-float-breakpoint-max) {
          font-size: 14px;
        }
      }
    }
    .input-filed .inputText{
      font-size: 16px;
      font-weight: $regular;
      color: $orange;
      line-height: 25px;
      letter-spacing: 0.27px;
      width: 100%;
      outline: none;
      border: 1px solid $grey-dark;
      height: 60px;
      padding-left: 30px;
      border-radius: 0;
    }
    .input-filed .inputText:invalid {
      box-shadow: none !important;
    }
    .input-filed .inputText:focus{
      border: 2px solid $orange;
      color: #1f1f1f;
    }
    .input-filed .floating-label {
      position: absolute;
      pointer-events: none;
      bottom: 14px;
      left: 10px;
      transition: 0.3s ease all;
      -webkit-backface-visibility: hidden;

    }
    .input-filed input:focus ~ .floating-label,
    .input-filed input:not(:focus):valid ~ .floating-label{

      bottom: 43px;
      background: #ffffff;
      left: -6px;
      padding: 0 15px;
      opacity: 1;
      transition: 0.3s ease all;
      -webkit-backface-visibility: hidden;

    }
  }



  .btn-arrow {
    margin: 0;
    padding: 13px 24px;
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 0 auto;
    }
    img {
      width: 5px;
      margin: 0px;
      margin-left: 4px;
    }
  }




  ul {
    margin: 0;
    padding: 0;
    li{
      font-weight: $light;
      font-size: 16px;
      line-height: 25px;
      display: block;
      float: left;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 14px;
        line-height: 20px;
      }
      label {
        padding: 28px 23px 29px 30px;
        @media screen and (max-width: $mobile-breakpoint) {
          padding: 32px 26px 25px 30px;
        }        
        cursor: pointer;
        -webkit-transition: all 0.25s linear;
        z-index: 9;
      }
      input[type=radio]{
        position: absolute;
        visibility: hidden;
      }
      .check{
        display: block;
        position: absolute;
        border: 1px solid #cccccc;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        top: 30px;
        left: 0;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear;
      }
    }
  }

  ul li:hover label{
    color: #1f1f1f;
  }
  ul li:hover .check {
    border: 1px solid #cccccc;
  }

  ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 12px;
    width: 12px;
    top: 3px;
    left: 3px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
  }

  input[type=radio]:checked ~ .check {
    border: 1px solid #cccccc;
  }

  input[type=radio]:checked ~ .check::before{
    background: $orange;
  }

  input[type=radio]:checked ~ label{
    color: #1f1f1f;
  }
  .butoni {
    display: inline-grid;
    margin: 0;
    padding: 0;
    background: white;
    border-radius: 0;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-color: white;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding: 15px 50px;
    }
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 0 auto;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 15px 30px;
      }
    }
    img {
      width: 5px;
      margin: 0px;
      margin-left: 4px;
    }
    .bttn {
      font-size: 14px;
      letter-spacing: 1.5px;
      line-height: 30px;
      padding: 15px 50px;
      margin: 0;
      border: 0;
      text-transform: uppercase;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 0;
      }
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 15px 30px;
        font-size: 12px;
        letter-spacing: 1.3px;
      }
    }
  }
}
#gform_confirmation_wrapper_1 ~ .orange-border {
  border: none!important;
}