.m18 {
  padding: 100px 0px 100px 0px;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 80px 0px 0px 0px;
  }
  p {
    margin: 0;
    padding: 0;
    &:first-child {
      margin-bottom: 40px;
      @media screen and (min-width: 992px) and (max-width: 1100px) {
        margin-bottom: 10px;
      }
    }
  }
  .regular20 {
    @media screen and (min-width: 992px) and (max-width: 1387px) {
      font-size: 16px;
      line-height: 25px;
    }
  }
  img {
    position: relative;
    z-index: 1;
  }
  h1 {
    position: relative;
    z-index: 2;
  }
  .margin-no {
    margin: 0;
  }
  .container {
    &.cm7 {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0;
        margin: 0;
        position: relative;
      }

      .m7-left {
        padding: 0;
        @media (max-width: $grid-float-breakpoint-max) {
          padding: 0;
        }
      }
    }
  }

  .m7-header-left {
    position: relative;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      box-shadow: 1px 1px 10px $grey-dark;
      z-index: 2;
    }

    .dv-header {
      @media (max-width: $grid-float-breakpoint-max) {
        height: 330px;
      }

      img {
        @media (max-width: $grid-float-breakpoint-max) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    h1 {
      color: $white;
      position: absolute;
      top: 60px;
      left: 50px;
      max-width: 600px;

      @media (max-width: $grid-float-breakpoint-max) {
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }

    .m7-logo {
      position: absolute;
      right: 0px;
      bottom: 0px;
      margin:0px;
      padding:0px;
      box-shadow: 1px 1px 10px $grey-dark;
      @media (max-width: $grid-float-breakpoint-max) {
        position: inherit;
        box-shadow: none;
      }
    }
  }

  .m7-text {
    padding-left: 0;
    padding-right: 0;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: left;
      background: $grey-light;
      padding: 0;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 25px;
    }

    p {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px;
      }
    }
  }
  .orange-border {
    border: 2px solid $orange;
    border-left: none;
    padding: 9.7% 15% 9.7% 21%;
    @media screen and (min-width: 993px) and (max-width: 1403px) {
      padding: 7% 11% 7% 15%;
    }
    @media screen and (max-width: $mobile-breakpoint) {
      border-left: 2px solid $orange;
      padding: 50px 15px 15px 15px;
      border-top: none;
      margin-bottom: 20px;
    }
  }
    .swiper-pagination-bullets {
    position: absolute;
    text-align: center;
    z-index: 10;

    @media (max-width: $grid-float-breakpoint-max) {
      bottom: 14px;
      left: 0;
      width: 100%;
    }
    @media (min-width: $grid-float-breakpoint) {
      right: 50px;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    .swiper-pagination-bullet {
      @media (max-width: $grid-float-breakpoint-max) {
        margin: 0 20px;
        width: 6px;
        height: 6px;
        background: #000;
        opacity: .2;
        outline: none;
      }
      @media (min-width: $grid-float-breakpoint) {
        margin: 34px 0;
        display: block;
        width: 6px;
        height: 6px;
        background: #000;
        opacity: .2;
        outline: none;
      }

    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background: $primary;

      &:focus {
        outline: none;
      }

      p {
        border: 2px solid $primary;
        padding: 15px;
        border-radius: 50%;
        position: absolute;
        margin: -14px -14px;
        line-height: 0;
        line-height: 0;

        &:focus {
          outline: none;
        }

      }

    }
  }
}