.m10 {
	@media screen and (max-width: $mobile-breakpoint) {
		text-align: center;
	}
	.grey-background {
		background: $grey-medium;
	}
	q {
		color: $orange;
		display: block;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 20px;
		}
	}
	.profile-pic img {
		border-radius: 50%;
		width:100px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 20px;
		}
	}
	.quote-p p {
		display: inline-block;
		@media screen and (max-width: $mobile-breakpoint) {
			display: block;
			margin-top: 8px;
			margin-bottom: 8px;
		}
	}
	.bullet {
	    margin-left: 15px;
	    margin-right: 15px;
	    margin-bottom: 4px;
	    display: inline-block;
	    width: 4px;
	    height: 4px;
	    background: $orange;
	    outline: none;
	    border-radius: 100%;
	}
	.box {
		background: $white;
		padding: 30px 66px;
	    text-align: center;
	    border: solid 1px $grey-light;
	    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	    @media screen and (max-width: 1445px) {
	    	padding: 30px 28px;
	    }
	    @media screen and (max-width: $mobile-breakpoint) {
	    	margin: 0 15px;
	    }
	}
	img {
		max-width: 220px;
		width: 100%;
	}
	.quote_row {
	    margin-bottom: 45px;
	}
	.medium14 {
		text-transform: uppercase;
		letter-spacing: 1.5px;
	}
	.light16 {
		letter-spacing: 0.8px;
	}
	a {
		border: 0;
	}
}