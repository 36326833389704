 .m32 {
  padding: 100px 0 100px 0;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 0;
  }

  .container {
    &.cm7 {
      @media (max-width: $grid-float-breakpoint-max) {

      }

      .m7-left {
        @media (max-width: $grid-float-breakpoint-max) {
          padding: 0px;
        }

      }
    }

  }

  .m7-header-left {
    position: relative;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      box-shadow: 1px 1px 10px $grey-dark;
      z-index: 2;
    }

    h1 {
      color: $white;
      position: absolute;
      top: 60px;
      left: 50px;
      max-width: 600px;


      @media (max-width: $grid-float-breakpoint-max) {
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;

      }

    }


    .dv-header {
      @media (max-width: $grid-float-breakpoint-max) {
        height: 330px;
      }

      img {
        @media (max-width: $grid-float-breakpoint-max) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .m7-logo {
      position: absolute;
      right: 0;
      bottom: 0;
      margin:0;
      padding:0;
      box-shadow: 1px 1px 10px $grey-dark;
      @media (max-width: $grid-float-breakpoint-max) {
        position: inherit;
        box-shadow: none;
      }
    }
  }

  .m7-text {
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: left;
      background: $grey-light;
      padding-top: 28px;
      padding-bottom: 32px;
      padding-left: 0;
      padding-right: 0;

    }

    h2 {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0 20px 0 0;
      }
    }
  }
  .light30 {
    font-size: 30px!important;
  }


  .box-space {
    padding: 50px 80px;
    border: 2px solid transparent;
    @media (min-width: $grid-breakpoints-lg) {
      margin-top: -99px;
    }
    @media (max-width: 1440px) {
      padding: 20px 50px 30px 50px;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 25px;
    }
  }
  .orange-border {
    border: 2px solid $orange;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }




  .gform_wrapper {
    margin: 0!important;
    .gform_heading {
      margin-bottom: 30px;
      h3 {
        font-family: $roboto;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.4px!important;
      }
      span {
        font-family: $roboto;
        font-size: 16px;
        width: 100%!important;
        @media screen and (max-width: 1300px) {
          font-size: 14px;
        }
      }
    }
    .gfield_label {
      display: none!important;
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      margin-bottom: 25px;
      display: block;
    }

    input[type='submit'] {
      font-size: 14px!important;
      letter-spacing: 1.5px;
      line-height: 30px;
      padding: 15px 50px;
      margin: 0!important;
      border: 0;
      text-transform: uppercase;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      color: #fff;
      background: #e67800;
      cursor: pointer;
      @media (max-width: 365px) {
        width: 100%;
        padding: 15px 0;
      }
    }
    .gfield_checkbox {
      label {
        display: inline;
        font-family: $roboto;
        font-size: 14px;
        line-height: 0!important;
        a {
          font-family: $roboto;
          font-weight: 300;
          font-size: 14px;
          line-height: 40px;
          letter-spacing: 0.3px;
          color: initial;
          border-bottom: 1px solid $dark;
          text-decoration-color: $black;
        }
      }
    }
  }

   .center-div {
     margin-left: auto;
     margin-right: auto;

   }
   @media screen and (max-width: 1300px) {
     h1 {
       font-size: 30px;
     }
     h3 {
       font-size: 25px;
     }
     .medium25 {
         font-size: 20px;
     }
   }
   .gfield_checkbox {
     li {
     line-height: 25px!important;
    }
   }
   .small-text {
     font-family: $roboto;
     font-size: 14px;
   }
   .iaccept {
     margin-bottom: 0!important;
     li {
       margin-bottom: 10px !important;
     }
     a {
       line-height: 0!important;
     }
   }
   .gfield_html {
     margin-bottom: 10px!important;
   }
   input[type='text'] {
     font-size: 16px!important;
     font-weight: $regular!important;
     color: $black!important;
     line-height: 25px!important;
     letter-spacing: 0.27px!important;
     width: 100%!important;
     outline: none!important;
     border: 1px solid $grey-dark!important;
     height: 60px!important;
     padding-left: 30px!important;
     border-radius: 0!important;
   }
   select {
     font-size: 16px!important;
     font-weight: $regular!important;
     line-height: 25px!important;
     letter-spacing: 0.27px!important;
     width: 100%!important;
     outline: none!important;
     border: 1px solid $grey-dark!important;
     height: 60px!important;
     padding-left: 30px!important;
     border-radius: 0!important;
     color: $dark!important;
   }
   textarea {
     font-size: 16px!important;
     font-weight: $regular!important;
     line-height: 25px!important;
     letter-spacing: 0.27px!important;
     width: 100%!important;
     outline: none!important;
     border: 1px solid $grey-dark!important;
     padding-left: 30px!important;
     padding-top: 18px;
     border-radius: 0!important;
     color: $dark!important;
     background-color: transparent!important;
     -webkit-appearance: none;
     &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
       font-size: 16px;
     }
     &::-moz-placeholder { /* Firefox 19+ */
       font-size: 16px;
     }
     &:-ms-input-placeholder { /* IE 10+ */
       font-size: 16px;
     }
     &:-moz-placeholder { /* Firefox 18- */
       font-size: 16px;
     }
   }

   .gform_footer {
     padding: 0!important;
     margin: 0!important;
   }
}
