.m4 {
  .limit-max-width {
    max-width: 479px;
    padding-left: 0;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media (max-width: $grid-float-breakpoint-max) {
    text-align: center;
  }
  .m4-title {
    margin-bottom: 47px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bttom: 33px;
    }
  }

  .m4-item-ico {
    @media (min-width: $grid-float-breakpoint) {
      float: left;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 20px;
    }

  }
  .m4-item-box {
    display: table;
    margin-bottom: 30px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: $grid-float-breakpoint) {
      padding-left: 30px;
    }
    @media (min-width: 1200px) and (max-width: 1300px) {
      padding-left: 17px;
    }

  }
  .m4-img {
    margin-bottom: 60px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 24px;
    }
  }
  h6 {
    color: $orange;
    text-align: center;
    text-transform: uppercase;
    padding-top: 25px;
    padding-bottom: 38px;
    margin-bottom: 0px;
    @media (max-width: $grid-float-breakpoint-max) {
      padding-top: 18px;
      padding-bottom: 30px;
      margin-bottom: 0px;

    }
  }

  .m4-logos {
    min-height: 106px;
    text-align: center;
    @media (max-width: $grid-float-breakpoint-max) {
      min-height: 36px;
    }
    position: relative;

    a {
      border-bottom: none;

    }
    img {
      vertical-align: bottom;
      @media (max-width: $grid-float-breakpoint-max) {
        max-width: 145px;
        margin-bottom: 36px;
      }
    }
  }

  .m4-cta {
    @media (max-width: $grid-float-breakpoint-max) {
      margin: 0 auto;

      a {
        margin-bottom: -130px;
      }
    }

  }


  .butoni {
    display: inline-grid;
    margin: 0;
    padding: 0;
    background: white;
    border-radius: 0;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-color: white;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding: 15px 50px;
    }
    /*
    a {
    @media screen and (min-width: 992px) {
        &:before {
          content: '';
          position: absolute;
          border-bottom: 2px solid #e67800;
          width: 37.3%;
          height: 15px;
          left: -34%;
        }
      }
    }
    */
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 0 auto;
      margin-top: 2rem;
      padding: 0;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 15px 30px;
      }
    }
    img {
      width: 5px;
      margin: 0px;
      margin-left: 4px;
    }
    .bttn {
      font-size: 14px!important;
      letter-spacing: 1.5px!important;
      line-height: 30px!important;
      padding: 15px 50px;
      margin: 0;
      border: 0;
      text-transform: uppercase!important;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 0;
      }
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 15px 30px;
        font-size: 12px!important;
        letter-spacing: 1.3px!important;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          padding: 0;
        }
      }
    }
  }

  hr {
    margin: 0;
    height: 1px;
    background: $orange;
    margin-left: -47%;
    margin-top: -30px;
    width: 70%;
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 0;
      height: 1px;
      background: $orange;
      margin-left: -15px;
      margin-top: -30px;
      width: 50%;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: none;
    }
  }


  video {
    width: 700px;
    max-width: 100%;
    height: auto;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding-left: 1em;
      text-indent: -.7em;
      font-size: inherit!important;
      letter-spacing: inherit!important;
      line-height: 25px;
    }
    li::before {
      content: "• ";
      color: $orange;
      font-size: 17px;
    }
  }

  .swiper-pagination-bullets {
    position: initial;
    text-align: center;
    z-index: 10;

    @media (max-width: $grid-float-breakpoint-max) {
      width: 100%;
      margin-bottom: 30px;
    }

    .swiper-pagination-bullet {
      @media (max-width: $grid-float-breakpoint-max) {
        margin: 18px 18px;
        width: 6px;
        height: 6px;
        background: #000;
        opacity: .2;
        outline: none;
      }
      @media (min-width: $grid-float-breakpoint) {
        margin: 18px 18px;
        display: inline-block;
        width: 6px;
        height: 6px;
        background: $primary;
        opacity: .7;
        outline: none;
      }
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background: $primary;

      &:focus {
        outline: none;
      }

      p {
        border: 2px solid $primary;
        padding: 15px;
        border-radius: 50%;
        position: absolute;
        margin: -14px -14px;
        line-height: 0;

        &:focus {
          outline: none;
        }
      }
    }
  }

}

