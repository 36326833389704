.m44 {

  .content-hub {
    position: relative;
    @media screen and (min-width: $mobile-breakpoint+1) {
      height: 610px;
    }
    @media screen and (max-width: $mobile-breakpoint) {
      margin-bottom: 65px;
    }

    .image-cover {
      height: 280px;
      max-width: 646px;
      background-size: cover !important;
      position: relative;
      @media screen and (max-width: 600px) {
        height: 147px;
      }

      .partner {
        background-color: #67898D;
        position: absolute;
        bottom: 0;
        margin: 0;
        padding: 15px 20px;
        font-size: 16px;
        color: white;
        font-weight: 500;

        img {
          margin-left: 12px;
        }

        @media screen and (max-width: $mobile-breakpoint) {
          width: 100%;
          padding: 5px 12px;
        }
      }
    }

    .bold {
      font-weight: 500;
      margin-top: 24px;
      margin-bottom: 12px;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-top: 14px;
      }
    }

    .label {
      font-weight: 500;
      float: right;
      background: #fff;
      margin-right: 20px;
      margin-top: 20px;
      padding: 2px 20px;
      cursor: default;
      @media screen and (max-width: 600px) {
        margin-right: 10px;
        margin-top: 10px;
      }
    }

    h4 {
      max-width: 626px;
      margin-bottom: 16px;
      @media screen and (max-width: $mobile-breakpoint) {
        margin-bottom: 12px;
      }
    }

    p {
      max-width: 481px;
    }

    .light16.bold.orange {
      display: inline-block;

      &.padding-left {
        padding-left: 8px;
      }
    }

    .circle {
      display: inline-block;
      background-color: $orange;
      width: 3px;
      height: 3px;
      border-radius: 50%;
      margin-bottom: 3px;
      margin-left: 8px;
    }

    a {
      border-bottom: none !important;
    }
  }

  .filter-button {
    cursor: pointer;
    font-weight: normal;
    width: fit-content;
    display: inline-block;
  }

  .filter-count {
    display: inline-block;
    margin-left: 22px;
  }

  a {
    .label {
      cursor: pointer !important;
    }
  }

  #options {
    display: none;
    width: fit-content;
    position: absolute;
    z-index: 2;
    background: white;
    padding: 50px 10px 0;
    border: solid 1px $grey-light;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: $mobile-breakpoint) {
      padding: 0;
      margin: 0;
      width: 100%;
      left: 0;
      position: fixed !important;
      top: 0;
      z-index: 9999 !important;
      //display: none !important;
      height: 100%;
      overflow: overlay;
    }

    .close-x {
      position: absolute;
      right: 18px;
      top: 18px;
      @media (max-width: $mobile-breakpoint) {
        // top: 34px;
      }
    }

    .toggle-filter {
      cursor: pointer;
    }

    .filter-footer {
      border-top: solid 1px #e4e4e4;
      padding-top: 23px;
      padding-right: 24px;
      padding-bottom: 20px;
      width: 100%;
      text-align: right;
      @media (max-width: $mobile-breakpoint) {
        border: none;
        padding-right: 0;
        div {
          display: block !important;
          text-align: center;
          margin: 0 auto !important;

          &.desktop-only {
            display: none !important;
          }

          &.button-btn {
            margin-bottom: 30px !important;
          }
        }
      }

      .filter-reset {
        display: inline-block;
        margin-right: 30px;
        cursor: pointer;


        p {
          font-weight: 500;
          font-size: 14px;
          text-transform: uppercase;
          font-family: $roboto;
        }

      }
    }

    .button-btn {
      display: inline-block;
      background-color: #e67800;
      color: #ffffff;
      width: 260px;
      text-align: center;
      border-radius: 0;
      padding: 18px 17px;
      font-family: $roboto;
      font-size: 14px;
      border: 0;
      text-transform: uppercase;
      cursor: pointer;
      font-weight: 500;

      .btn-arroww {
        margin-left: 6px;
      }
    }

    .filters-all {
      padding-bottom: 30px;
    }

    label {
      margin-left: 8px;
      @media (max-width: $mobile-breakpoint) {
        margin-left: 45px;
      }
    }

    .main-content-hub {
      input {
        display: none !important;
      }

      label {
        font-weight: 500;
        margin-bottom: 20px;
        margin-left: -13px;

        &:before,
        &:after {
          display: none !important;
        }
      }

      @media (max-width: $mobile-breakpoint) {
        border-bottom: 1px solid #E4E4E4;
        padding: 20px 0;
        label {
          font-size: 25px;
          margin-left: 24px;
          display: initial;
        }
        &.opened {
          border: none !important;
        }
      }
    }
  }


  .filters {
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: $mobile-breakpoint) {
      display: block;
    }

    .option-set {
      border-right: solid 1px #e4e4e4;
      padding-right: 38px;
      padding-left: 40px;
      //height: 230px;
      @media (max-width: $mobile-breakpoint) {
        padding: 0;
        height: initial;
        position: relative;
      }
    }

    &:last-child {
      .option-set {
        border: none;
      }
    }
  }

  #no-results {
    display: none;
  }

  h2 {
    margin: 180px auto 120px;
    max-width: 828px;
    color: #cccccc;
    text-align: center;
    font-weight: bold;
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 80px auto 60px;
    }
  }


  .filter-set {
    input {
      position: absolute;
      opacity: 0;

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        margin-left: 0;
      }

      & + label:before {
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;

        position: absolute;
        left: -22px;
        top: 4px;


        width: 15px;
        height: 15px;
        content: " ";
        background-image: url(./images/svg/checkbox_default.svg);


      }

      &:hover + label:before {
        background-image: url(./images/svg/checkbox_hover.svg);
      }

      &:checked + label {
        //font-weight: 500;
      }

      &:checked + label:before {
        background-image: url(./images/svg/checkbox_active.svg);
      }

    }

    @media (max-width: $mobile-breakpoint) {
      display: none;
    }

    &.main-content-hub {
      @media (max-width: $mobile-breakpoint) {
        display: block;
      }
    }
  }

  .selected-filter {
    margin-bottom: 80px;

    .remove-filter {
      display: inline-block;
      font-size: 16px;
      color: orange;
      font-weight: bold;
      margin-right: 35px;
      cursor: pointer;

      img {
        width: 15px;
        height: 15px;
        margin-left: 5px;
      }
    }
  }

  .hide-this {
    display: none;
  }

  .butoni {
    cursor: pointer;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: none;
    }
    display: inline-grid;
    margin: 0 auto;
    padding: 0;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding: 15px 50px;
    }
    background: white;
    border-radius: 0;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-color: white;
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-left: 3px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 15px 30px;
      }
    }
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 0 auto;
    }

    img {
      width: 5px;
      margin-left: 11px;
      margin-top: -4px;
      display: inline-block;
      transform: rotate(90deg);
    }

    .bttn {
      font-size: 14px;
      letter-spacing: 1.5px;
      line-height: 30px;
      padding: 15px 50px;
      margin: 0;
      border: 0;
      text-transform: uppercase;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 0;
      }
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 15px 30px;
        font-size: 12px;
        letter-spacing: 1.3px;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          padding: 0;
        }
      }
    }
  }

  hr {
    border-top: 1px solid #979797;
    position: absolute;
    top: 13px;
    width: 100%;
    z-index: -1;
  }

  p.mobile-only {
    margin-left: 24px;
    margin-top: 7px;
    font-size: 18px;
    font-family: $roboto;
    font-weight: 500;
  }


  .mobile-only.triangle {
    @media (max-width: $mobile-breakpoint) {
      position: absolute;
      top: 0;
      width: 100%;
      height: 78px;
      z-index: 9999;
      img {
        float: right;
        margin-top: 35px;
        margin-right: 30px;
      }
    }
  }
  .opened {
    img {
      transform: rotate(180deg);
    }
  }
}