@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300&display=swap');

// Font Variables
$roboto: 'Roboto', sans-serif;
$rajdhani: 'Rajdhani', sans-serif;
$roboto-slab: 'Roboto Slab', serif;

$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;

//Color Variables and Color Classes
$orange: #e67800;
$dark: #101010;
$grey-dark: #cccccc;
$grey-medium: #e4e4e4;
$grey-light: #f6f6f6;
$white: #ffffff;

.orange {
	color: $orange;
}
.dark {
	color: $dark;
}
.grey-dark {
	color: $grey-dark;
}
.grey-medium {
	color: $grey-medium;
}
.grey-light {
	color: $grey-light;
}
.white {
	color: white;
}
// Typography 
body {
  font-family: $roboto;
}
h1 {
	font-family: $rajdhani;
	font-weight: $bold;
	font-size: 60px;
	line-height: 70px;
	letter-spacing: 1px;
	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 30px;
		line-height: 35px;
	}

}
h2 {
	font-family: $rajdhani;
	font-weight: $bold;
	font-size: 40px;
	line-height: 50px;
	letter-spacing: 0.8px;
	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 25px;
		line-height: 30px;
	}
}
h3 {
	font-family: $rajdhani;
	font-weight: $bold;
	font-size: 30px;
	line-height: 40px;
	letter-spacing: 0.4px;
	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 20px;
		line-height: 25px;
	}
}
h4 {
	font-family: $roboto;
	font-weight: $medium;
	font-size: 25px;
	line-height: 30px;
	letter-spacing: 0.4px;
	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 18px;
		line-height: 25px;
	}
}
h5 {
	font-family: $rajdhani;
	font-weight: $bold;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: 0.3px;
	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 18px;
		line-height: 25px;
	}
}
h6 {
	font-family: $roboto;
	font-weight: $medium;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 1.5px;
	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 1.3px;
	}
}
p {
	font-family: $roboto;
	font-weight: $light;
	font-size: 25px;
	line-height: 40px;
	letter-spacing: 0.3px;
	color: $dark;
	&.light25 {
		font-weight: $light;
		font-size: 25px;
		line-height: 40px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 20px;
			line-height: 35px;
			a {
				font-size: 20px;
			}
		}
		a {
			font-size: 25px;
		}
	}
	&.medium25 {
		font-weight: $medium;
		font-size: 25px;
		line-height: 40px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 20px;
			line-height: 35px;
			a {
				font-size: 20px;
			}
		}
		a {
			font-size: 25px;
		}
	}
	&.regular20 {
		font-family: $roboto-slab;
		font-weight: $regular;
		font-size: 20px;
		line-height: 30px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 16px;
			line-height: 25px;
			a {
				font-size: 16px;
			}
		}
		a {
			font-size: 20px;
		}
	}
	&.light20 {
		font-weight: $light;
		font-size: 20px;
		line-height: 30px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 16px;
			line-height: 25px;
			a {
				font-size: 16px;
			}
		}
		a {
			font-size: 20px;
		}
	}
	&.light16 {
		font-weight: $light;
		font-size: 16px;
		line-height: 25px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 14px;
			line-height: 20px;
			a {
				font-size: 14px;
			}
		}
		a {
			font-size: 16px;
		}
	}
	&.regular16 {
		font-weight: $regular;
		font-size: 16px;
		line-height: 25px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 14px;
			line-height: 20px;
			a {
				font-size: 14px;
			}
		}
		a {
			font-size: 16px;
		}
		&.bold {
			font-weight: 600;
		}
	}
	&.light14 {
		font-weight: $light;
		font-size: 14px;
		line-height: 20px;
		a {
			font-size: 14px;
		}
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 13px;
			a {
				font-size: 13px;
			}
		}
	}
	&.regular14 {
		font-weight: $regular;
		font-size: 14px;
		line-height: 20px;
		a {
			font-size: 14px;
		}
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 13px;
			a {
				font-size: 13px;
			}
		}
	}
	&.medium14 {
		font-weight: $medium;
		font-size: 14px;
		line-height: 20px;
		a {
			font-size: 14px;
		}
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 13px;
			a {
				font-size: 13px;
			}
		}
	}
}
ul li {
	font-family: $roboto;
	font-weight: $light;
	font-size: 25px;
	line-height: 40px;
	letter-spacing: 0.3px;
	color: $dark;
	&.light25 {
		font-weight: $light;
		font-size: 25px;
		line-height: 40px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 20px;
			line-height: 35px;
		}
	}
}
blockquote,
q {
	font-family: $roboto-slab;
	font-weight: $regular;
	font-size: 20px;
	line-height: 35px;
	letter-spacing: 0.3px;
	color: $dark;
	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 16px;
		line-height: 25px;
	}
}

a {
	font-family: $roboto;
	font-weight: $medium;
	font-size: 25px;
	line-height: 40px;
	letter-spacing: 0.3px;
	color: $dark;
	border-bottom: 2px solid $orange;
	&.large {
		font-size: 25px;
		line-height: 40px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 20px;
		}
	}
	&.medium {
		font-size: 16px;
		line-height: 30px;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 14px;
		}
	}
	&.small {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 1.5px;
		border: none;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 12px;
		}
	}
	&.downloadlink {
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 1.5px;
		border: none;
		@media screen and (max-width: $mobile-breakpoint) {
			font-size: 12px;
		}
	}

	&:hover {
		text-decoration: none;
		color: inherit;
	}

}
.btn {
	font-family: $roboto;
	font-weight: $medium;
	font-size: 14px;
	letter-spacing: 1.3px;
	color: $dark;
	text-transform: uppercase;
	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 12px;
		letter-spacing: 1.3px;
	}

}

/* IE11 
@media all and (-ms-high-contrast:none)
{
*::-ms-backdrop,
[data-aos^=fade][data-aos^=fade],[data-aos^=zoom][data-aos^=zoom]{
opacity: 1;
}
}
*/

#hs-eu-cookie-confirmation p a {
	color: #E67800 !important;
}