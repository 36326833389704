.m20 {

	&.grey-bcg {
		background: $grey-light;
	}

	h3 {
		text-align: center;
		margin-bottom: 50px;

		@media (max-width: $grid-float-breakpoint-max) {
			margin-bottom: 20px;
		}

	}

	.m20-slider-content {
		position: relative;

		@media (max-width: $grid-float-breakpoint-max) {
			height: 330px;

			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}

		}

		h5 {
			position: absolute;
			bottom: 10px;
			left: 30px;
			color: $white;

			&:after {
				content: "";
				display: inline-block;
				background-image: url(./images/white-arrow-right.svg);
				background-repeat: no-repeat;
				width: 9px;
				height: 9px;
				margin-left: 10px;
				margin-top: 14px;
			}
		}
	}

	.swiper-h-pagination {
		margin: 55px 0px 50px;

		@media (max-width: $grid-float-breakpoint-max) {
			margin: 25px 0px 25px;
		}
	}

	.swiper-pagination-bullets {
		position: relative;
		text-align: center;
		z-index: 10;

		bottom: 10px;
		left: 0;
		width: 100%;

		.swiper-pagination-bullet {
			margin: 0 20px;
			width: 6px;
			height: 6px;
			background: #000;
			opacity: .2;
			outline: none;
		}

		.swiper-pagination-bullet-active {
			opacity: 1;
			background: $primary;

			&:focus {
				outline: none;
			}

			p {
				border: 2px solid $primary;
				padding: 15px;
				border-radius: 50%;
				position: absolute;
				margin: -14px -14px;
				line-height: 0;

				&:focus {
					outline: none;
				}
			}
		}
	}

	.btn-back {
		background: white;
		border-radius: 0;
		box-shadow: 0 0 10px rgba(0, 0, 0, .2);
		border-color: white;
		padding: 18px 50px;
		font-size: 14px;
		margin-top: 30px;

		@media screen and (max-width: $mobile-breakpoint) {
			padding: 18px 30px;
		}

		&:before {
			content: "";
			display: inline-block;
			background-image: url(./images/btn-arrow.svg);
			background-repeat: no-repeat;
			width: 9px;
			height: 9px;
			margin-right: 0;
			margin-left: 13px;
			float: right;
			margin-top: 8px;
		}
	}


	.slider-icon {
		height: 71px;
		background: $white;
		text-align: right;

		.img-ico {
			height: 54px;
			padding-top: 8px;
			padding-right: 12px;

			img {
				box-shadow: none;
				width: auto;
				height: 54px;
			}
		}
	}

	.m20-slider-content {
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		-o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	}

	.swiper-container.horizontal-slider {
		padding: 5px 0;
	}

	.pagination1,
	.pagination2,
	.pagination3,
	.pagination4 {
		@media (min-width: 992px) {
			display: none;
		}
	}
}

/* special per landing page */
.landing-page {
	.m20 {
		.m20-slider-content {
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
			-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
			-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
			-o-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		}

		.swiper-container.horizontal-slider {
			padding: 5px 0;
		}
	}
}