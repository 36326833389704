.m24 {
	@media screen and (max-width: $mobile-breakpoint) {
		margin-top: 3rem;
	}
	.margin-top {
		@media screen and (max-width: $mobile-breakpoint) {
			margin-top: 2rem;
		}
		p {
			margin: 0px;
		}
	}
	#map2 {
		width: 100%;
		height: 100%;
	}
	.margin-top-2 {
		@media screen and (max-width: $mobile-breakpoint) {
			margin-top: 2rem;
		}
	}
	h2 {
		text-align: center;

		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 12px;
		}
	}
	p {
		margin-bottom: 70px;
	}
	img {
		&.border-radius-img {
		border-radius: 50%;
		width: 100px;
		}
	}
	p {
		&.medium25 {
			margin-bottom: 35px;
			@media screen and (max-width: $mobile-breakpoint) {
				text-align: center;
				margin-bottom: 25px;
			}
		}
		&.light16 {
			a {
				font-weight: $light!important;
				font-size: 16px!important;
				line-height: 25px!important;
				@media screen and (max-width: $mobile-breakpoint) {
					font-size: 14px!important;
					line-height: 20px!important;
				}
			}
		}
		&.medium14 {
			text-transform: uppercase;
		}
		&.margin-bottom {
			margin-top: 1rem;
		}
	}
	.margin-after-map {
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
	a {
		word-wrap: break-word;
		border: none;
		&.social-link {
			border: none;
			letter-spacing: 1.5px;
			font-weight: $medium;
			margin-right: 18px;
			&:after {
				content: "";
				display: inline-block;
				background-image: url(./images/btn-arrow.svg);
				background-repeat: no-repeat;
				width: 10px;
				height: 10px;
				margin-left: 8px;
			}
			&:hover {
				color: $orange;
			}
		}
		&.alight14 {
			font-weight: $light;
			font-size: 14px;
			line-height: 20px;
			@media screen and (max-width: $mobile-breakpoint) {
				font-size: 13px;
			}
			&:last:child {
				margin-bottom: 1rem;
			}
		}
		span {
			font-weight: $regular;
			font-size: 14px;
			line-height: 20px;
			@media screen and (max-width: $mobile-breakpoint) {
				font-size: 13px;
			}
		}
		&.margin-bottom-tel {
			margin-bottom: 10px;
		}

	}
	span {
		font-weight: $regular;
		margin-right: 18px;
	}
	.kontakt-nomargine {
		margin: 0;
	}
	.kontakt-person {
		margin-bottom: 45px;
		@media screen and (max-width: $mobile-breakpoint) {
			border-bottom: 1px solid $grey-medium;
			margin-bottom: 30px;
			padding-bottom: 23px;
		}
	}
	@media screen and (max-width: $mobile-breakpoint) {
		.img-mobile-kontakt {
			width: 145px;
		}
		.info-mobile-kontakt {
			width: calc(100% - 145px);
		}
	}
	@media screen and (max-width: 360px) {
		.info-mobile-kontakt {
			width: 100%;
		}
	}
	.contact_mail_tel {
		min-height: 100px;
		@media screen and (max-width: $mobile-breakpoint) {
			min-height: 0;
		}
	}
	.opening_hours {
		p {
			display: inline-block;
			margin: 0;
			&.medium14 {
				margin-bottom: 1rem;
			}
			&:first-child {
				width: 50%;
				min-width: 160px;
				@media screen and (max-width: $mobile-breakpoint) {
					width: 160px;
				}

			}
		}
	}
	@media only screen and (max-width: $mobile-breakpoint) {
		#first {
			order: 2;
		}
		#second {
			order: 1;
		}
	}
	.maps {
		height: 300px;
		width: 100%;
	}
	.hubspot {
		margin: 50px 0;
	}
}


