.m11 {
	padding-top: 20px;
	position: relative;
	@media (max-width: $grid-float-breakpoint-max) {
		text-align: center;
	}

	.col-m11 {
		@media (min-width: $grid-float-breakpoint) {
			flex: 0 0 860px;
			margin: 0 auto;
		}

	}

	.quote-start {
		@media (min-width: $grid-float-breakpoint) {
			position: absolute;
			text-align: center;
			right: 46%;
			top: -61px;
		}
		@media (max-width: $grid-float-breakpoint-max) {
			display: block;
			margin: 16px auto;
		}

	}

	.quote-end {
		@media (min-width: $grid-float-breakpoint) {
			position: absolute;
			right: 46%;
			bottom: -61px;
		}

		@media (max-width: $grid-float-breakpoint-max) {
			display: block;
			margin: 30px auto;
		}

	}

	.m11-quote {
		margin: 60px 0;
		@media (min-width: $grid-float-breakpoint) {
			min-height: 520px;
			position: relative;
			border: 2px solid $orange;
			padding: 45px 182px 130px 40px;
			max-width: 544px;
			float: left;

			&.cta-active {
				min-height: 520px;
				position: relative;
				z-index: 1;
			}
		}

		@media (max-width: $grid-float-breakpoint-max) {
			border: 2px solid $orange;
			margin: 0px 30px;
			padding: 0px 30px 26px;

		}

		blockquote {
			@media (max-width: $grid-float-breakpoint-max) {
				margin-top: 200px;
				text-align: left;
			}
		}

		&.no-foto {
			blockquote {
				@media (max-width: $grid-float-breakpoint-max) {
					margin-top: 20px;
				}
			}
		}

		.quote-author {
			@media (min-width: $grid-float-breakpoint) {
				position: absolute;
				bottom: 48px;
			}

			@media (max-width: $grid-float-breakpoint-max) {
				text-align: left;
			}

			p {
				margin-bottom: 0px;

				&.orange {
					color: $orange;
					font-weight: 400;
				}
			}
		}

		&.no-foto {

			@media (min-width: $grid-float-breakpoint) {
				float: initial !important;
				margin-left: auto !important;
				margin-right: auto !important;
				padding: 45px 145px 130px 40px;
			}
		}
	}

	img {
		&.m11-img {
			position: relative;
			@media (min-width: $grid-float-breakpoint) {
				max-width: 402px;
				margin-left: -115px;
				margin-top: 115px;
				z-index: 3;
				&.cta-active {
					margin-top: 115px;
				}
			}

			@media (max-width: $grid-float-breakpoint-max) {
				max-width: 250px;
				margin-bottom: -150px;
			}
		}
	}

	.m11-cta {
		display: block;
		margin-top: 5px;
		position: relative;
		z-index: 999;
		text-transform: uppercase;

		&:after {
			content: "";
			display: inline-block;
			background-image: url(./images/btn-arrow.svg);
			background-repeat: no-repeat;
			width: 9px;
			height: 9px;
			margin-left: 8px;
		}
	}

	.swiper-pagination-bullets {
		text-align: center;
		z-index: 10;

		@media (max-width: $grid-float-breakpoint-max) {
			top: 26.5rem;
			left: 0;
			width: 100%;
		}
		@media (min-width: $grid-float-breakpoint) {
			position: absolute;
			right: 50px;
			top: 50%;
			transform: translate3d(0, -50%, 0);
		}

		.swiper-pagination-bullet {
			@media (max-width: $grid-float-breakpoint-max) {
				margin: 0 20px;
				width: 6px;
				height: 6px;
				background: #000;
				opacity: .2;
				outline: none;
			}
			@media (min-width: $grid-float-breakpoint) {
				margin: 34px 0;
				display: block;
				width: 6px;
				height: 6px;
				background: #000;
				opacity: .2;
				outline: none;
			}

		}

		.swiper-pagination-bullet-active {
			opacity: 1;
			background: $primary;

			&:focus {
				outline: none;
			}

			p {
				border: 2px solid $primary;
				padding: 15px;
				border-radius: 50%;
				position: absolute;
				margin: -14px -14px;
				line-height: 0;

				&:focus {
					outline: none;
				}

			}

		}
	}

}