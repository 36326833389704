.m43 {
  height: 385px;
  background-size: cover!important;
  text-align: center;
  color: #ffffff;
  padding-top: 100px;
  @media (max-width: 991px) {
    height: 309px;
    padding-top: 92px;
  }
  h2 {
    margin-bottom: 25px;
    font-family: $rajdhani;
    font-weight: bold;
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 991px) {
      max-width: 500px;
    }
  }
  a {
    display: inline-block;
    background-color: #e67800;
    color: #ffffff;
    width: 260px;
    border-radius: 0;
    padding: 10px 17px;
    font-family: $roboto;
    font-size: 14px;
    border: 0;
    text-transform: uppercase;
    .btn-arroww {
      margin-left: 6px;
    }

  }
}