.m37 {
  @media (max-width: $mobile-breakpoint) {
    margin-top: 80px;
  }
  padding: 0;
  .container {
    padding: 0;
    margin: 0;
  }
  #map {
    width: 100%;
    height: 650px;
    @media (max-width: $mobile-breakpoint) {
      height: 400px;
    }
    .gm-style-iw {
      width: 313px;
      border: 1px solid $orange;
      @media (max-width: $mobile-breakpoint) {
        width: 300px;
      }
      padding: 0;
      border-radius: 0;
      .gm-style-iw-d {
        width: 313px;
        @media (max-width: $mobile-breakpoint) {
          width: 300px;
        }
        padding: 0;
        border-radius: 0;
        overflow: hidden!important;
        .map-text-info {
          padding: 15px 15px 15px 20px;
          @media (max-width: $mobile-breakpoint) {
            padding: 15px 0 15px 20px;
          }
          h3 {
            font-family: $roboto;
            font-weight: 300;
            font-size: 22px;
            @media (max-width: $mobile-breakpoint) {
              font-size: 18px;
            }
          }
          p {
            font-family: $roboto;
            font-weight: 300;
            font-size: 18px;
            line-height: 1.3;
            margin-bottom: 20px;
            @media (max-width: $mobile-breakpoint) {
              font-size: 15px;
              margin-bottom: 10px;
            }
          }
          a {
            font-family: $roboto;
            font-weight: 300;
            font-size: 16px;
            @media (max-width: $mobile-breakpoint) {
              font-size: 14px;
              margin-bottom: 0;
              line-height: 20px;
            }
            text-decoration: none;
            border: none;
            .email {
              margin-right: 15px;
              @media (max-width: $mobile-breakpoint) {
                margin-right: 8px;
                margin-bottom: 0;
                img {
                  width: 20px;
                }
              }
            }
            .fax {
              margin-right: 15px;
              @media (max-width: $mobile-breakpoint) {
                margin-right: 8px;
                margin-bottom: 0;
                img {
                  width: 20px;
                }
              }
            }
            .tel {
              margin-right: 15px;
              @media (max-width: $mobile-breakpoint) {
                margin-right: 8px;
                margin-bottom: 0;
                img {
                  width: 20px;
                }
              }
            }
          }
        }
      }
    }
    .gm-style .gm-style-iw-t::before, .gm-style .gm-style-iw-t::after {
      display: none;
    }
  }
  button.gm-ui-hover-effect {
    top: 0!important;
    right: 0!important;
  }
}