.m29 {
  padding: 100px 0px 100px 0px;
  margin-bottom: 80px;

  @media (max-width: $grid-float-breakpoint-max) {
    padding: 80px 0px 0px 0px;
    margin-bottom: 0px;
  }

  .container {
    &.cm29 {
      @media (max-width: $grid-float-breakpoint-max) {

      }

      .m29-left {
        @media (max-width: $grid-float-breakpoint-max) {
          padding: 0px;
        }

      }
    }
  }

  .m29-header-left {
    position: relative;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      z-index: 2;

      &.panel-download {
        padding-bottom: 50px;
        background: $grey-light;
      }
    }

    .dv-header {
      @media (max-width: $grid-float-breakpoint-max) {
        height: 330px;
      }

      img {
        @media (max-width: $grid-float-breakpoint-max) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    h1 {
      color: $white;
      position: absolute;
      top: 60px;
      left: 50px;
      max-width: 600px;

      @media (max-width: $grid-float-breakpoint-max) {
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;

      }

    }
  }

  .m29-text {
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      padding-top: 28px;
      padding-bottom: 32px;

    }

    h2 {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px 20px;
      }
    }
  }

  .btn-download {
      background: white;
      border-radius: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
      border-color: white;
      padding: 18px 73px 18px 50px;
      font-size: 14px;
      margin-left: 10px;
      margin-top: 21px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 14px;
      padding: 18px 53px 18px 30px;
    }

    &:after {
      content: "";
      position: absolute;
      background-image: url(./images/pdf_download.svg);
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      margin-left: 5px;
      bottom: 15px;
    }
  }

  .m29-list-items {
    @media (max-width: $grid-float-breakpoint-max) {
      padding-top: 35px;
      background: $grey-light;
    }
    h6 {
      color: $orange;
      text-transform: uppercase;
      margin-bottom: 17px;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 12px;
      }
    }

    .m29-bold {
      font-weight: $regular;
    }
    .m29-light {
      font-weight: $light;
      a {
        font-weight: $light!important;
        font-size: 16px!important;
        line-height: 25px!important;
        @media screen and (max-width: $mobile-breakpoint) {
          font-size: 14px!important;
          line-height: 20px!important;
        }
      }
    }

    .m29-box-items {
      margin-bottom: 50px;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 24px;
      }

      > div {
        margin-bottom: 5px;
        @media (max-width: $grid-float-breakpoint-max) {
          margin-bottom: 0px;
        }
      }
    }
  }
}