.m27 {
  @media (max-width: $grid-float-breakpoint-max) {
    text-align: center;
  }

  .m27-item-ico {
    @media (min-width: $grid-float-breakpoint) {
      float: left;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 20px;
    }

  }

  .m27-item-box {
    display: table;

    @media (min-width: 1200px) and (max-width: 1300px) {
      padding-left: 17px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .m27-left {

    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0px;

      p {
        &.light14 {
          padding: 10px;
          margin-bottom: 20px;
          background: $grey-light;
        }
      }
    }

    .m27-left-box {
      @media (min-width: $grid-float-breakpoint) {
        max-width: 686px;
        float: right;
      }

      .m27-img {
        margin-bottom: 20px;
        @media (max-width: $grid-float-breakpoint-max) {
          margin-bottom: 0px;
        }
      }
    }

  }

  .m27-right {
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: left;
    }
    @media (min-width: $grid-float-breakpoint) {
      margin-bottom: 30px;
    }

    .m27-item-person {
      .regular16 {
        color: $orange;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .light16 {
        margin-bottom: 5px;
      }

      ul {
        padding-left: 15px;
        @media screen and (max-width: $grid-float-breakpoint-max) {
          padding-left: 15px;
        }
        li {
          color: #3c3c3c;
          display: block;
          list-style: none;
          margin: 0 20px 16px 0;
          padding: 0;
          position: relative;
          @media screen and (max-width: $grid-float-breakpoint-max) {

          }
          &:after {
            color: $orange;
            content: "\2022";
            display: inline-block;
            font-size: 21px;
            position: absolute;
            right: 0;
            left: -15px;
            top: -8px;
            @media screen and (max-width: $grid-float-breakpoint-max) {

            }
          }
        }
      }

      a {
        font-weight: 500;
        cursor: pointer;
        line-height: 30px;
        &:after {
          content: "";
          display: inline-block;
          background-image: url(./images/btn-arrow.svg);
          background-repeat: no-repeat;
          width: 9px;
          height: 9px;
          margin-left: 8px;
          transform: rotate(-90deg);
          margin-bottom: 3px;
          margin-right: 3px;
        }
        &.collapsed {
          &:after {
            transform: rotate(90deg);
            margin-bottom: -2px;
          }
        }
      }
    }

    .m27-item-social {
      margin-bottom: 22px;

      .m27cta {
        font-weight: 500;
        @media (min-width: $grid-float-breakpoint) {
          margin-right: 20px;
        }

        &:after {
          content: "";
          display: inline-block;
          background-image: url(./images/btn-arrow.svg);
          background-repeat: no-repeat;
          width: 9px;
          height: 9px;
          margin-left: 8px;
        }
      }
    }
  }
}