.m48 {
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  .sbi_header_text {
    width: 200px;
    margin: 0 auto 30px;
}



.sbi-owl-prev {
  background-image: url(./images/svg/arrow-swiper.svg);
  background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    height: 50px;
    box-shadow: none;
    border-radius: initial;
    padding: 0;
    margin: 0;
    left: -50px;
    svg {
      display: none!important;
    }
  }
  .sbi_carousel:hover .sbi-owl-nav>.sbi-owl-prev {
    background-image: url(./images/svg/arrow-swiper.svg);
    box-shadow: none;
    transform: initial;
}


.sbi-owl-next {
  background-image: url(./images/svg/_arrow-swiper-right.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    height: 50px;
    box-shadow: none;
    border-radius: initial;
    padding: 0;
    margin: 0;
    right: -50px;
  svg {
    display: none!important;
  }
}


.sbi_carousel:hover .sbi-owl-nav>.sbi-owl-next {
  background-image: url(./images/svg/_arrow-swiper-right.svg);
  box-shadow: none;
  transform: initial;
}



.hide-mobile {
  @media (max-width: 991px) {
    display: none;
  }
}
.show-mobile {
  display: none;
  @media (max-width: 991px) {
    display: initial;
  }
}

}