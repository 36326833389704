.m9 {
	p {
		margin: 0;
		@media screen and (max-width: $mobile-breakpoint) {
			width: 91%;
		}
	}
	p.medium14 {
	    text-transform: uppercase;
	}
	.table td {
	    padding: 1rem;
	    vertical-align: top;

	}
	h2 {
		margin-bottom: 62px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 0;
		}
	}
	@media (min-width: 993px) {
		.d-lg-block {
	    	display: table-cell;
		}
	}
	@media screen and (max-width: $mobile-breakpoint) {
		.table td:first-child:after {
		    content: "";
		    display: inline-block;
		    background-image: url(./images/btn-arrow-black.svg);
		    background-repeat: no-repeat;
		    width: 9px;
		    height: 9px;
		    margin-left: 0;
		    float: right;
		    margin-top: 8px;
		}
	}
	.table th {
		border: none;
		border-bottom: 2px solid $orange;
	}
	.table td {
	    border-top: none;
	    border-bottom: 1px solid #dee2e6;

		&:hover {
			border: none;
		}
	}
	.table td:last-child:after {
	    content: "";
	    display: inline-block;
	    background-image: url(./images/btn-arrow-black.svg);
	    background-repeat: no-repeat;
	    width: 9px;
	    height: 9px;
	    margin-left: 8px;
	    float: right;
	    margin-top: 3%;
	}
	.table-hover {
		td {
			border-top: 1px solid #dee2e6;
		}

	}
	.table-hover tbody tr:hover {
		background: none;
		td {
			border:none!important;
		}
		td:first-child:before {
			box-sizing: border-box;
			content:'';
			position: absolute;
			left: 15px;
			right: 15px;
			margin-top: -17px;
			display: block;
			border-bottom: none;
			height: 59px;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
			@media screen and (max-width: $mobile-breakpoint) {
				height: 58px;
			}
		}

	    cursor: pointer;
	}
	.table-hover tbody tr:hover td:last-child:after {
	    background-image: url(./images/btn-arrow.svg);
	}
	.table-hover tbody tr:hover td.hover-orange p {
	    color: $orange;
	}
	.table td p:last-child {
		display: inline-block;
	}
	.bullet {
		margin-left: 10px;
		margin-right: 10px;
	    margin-bottom: 5px;
	    display: inline-block;
	    width: 4px;
	    height: 4px;
	    background: #e67800;
	    outline: none;
	    border-radius: 100%;
	}
}