.single-jobs {

  .mobile-only {
  }
  
   @media screen and (max-width: $mobile-breakpoint) {
    .no-padding {
      padding: 0;
    }
    .margin-30 {
      margin: 0px 15px;
    }
    .margin-15 {
      margin: 0px 15px;
    }
    .margin-bottom-15 {
      margin-bottom: 15px;
    }
  }
  h2 {
    margin-bottom: 38px;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-bottom: 15px;
    }
  }
  .jb-title {
    color: $orange;
  }
  ul {
  	list-style: none;
  	padding: 0;
  	margin: 0;
  }
  li {
  	padding-left: 1em; 
  	text-indent: -.7em;
    font-family: $roboto;
    font-weight: $light;
    font-size: 25px;
    line-height: 40px;
    letter-spacing: 0.3px;
    color: $dark;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 20px;
      line-height: 35px;
      letter-spacing: 0.3px;
    }
    &.light25 {
      font-weight: $light;
      font-size: 25px;
      line-height: 40px;
      @media screen and (max-width: $mobile-breakpoint) {
        font-size: 20px;
        line-height: 35px;
      }
    }
  }
li::before {
   content: "• ";
   color: $orange; /* or whatever color you prefer */
 }
  .row {
    margin: 80px 0rem;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-bottom: 20px;
      margin-top: 0;
    }
  }
  article {
    .row {
      margin: 0 -15px;
    }
  }
  section {
    .row {
      margin: 0 -15px;
    }
  }

.job-desc-header {
  padding: 100px 0px 54px 0px;
  margin-bottom: 100px;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 80px 0px 0px 0px;
    margin-bottom: 60px;
  }
  p {
    margin: 0;
    padding: 0;
    &:first-child {
      margin-bottom: 40px;
    }
  }
  img {
    position: relative;
    z-index: 1;
  }
  h1 {
    position: relative;
    z-index: 2;
  }
  .margin-no {
    margin: 0;
  }
  .container {
    &.cm7 {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px;
        margin: 0;
      }


    }
  }

  .m7-header-left {
    position: relative;
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: center;
      box-shadow: 1px 1px 10px $grey-dark;
      z-index: 2;
    }
    h1 {
      color: $white;
      position: absolute;
      top: 60px;
      left: 50px;
      max-width: 600px;

      @media (max-width: $grid-float-breakpoint-max) {
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto;

      }

    }

    .m7-logo {
      position: absolute;
      right: 0px;
      bottom: 0px;
      margin:0px;
      padding:0px;
      box-shadow: 1px 1px 10px $grey-dark;
      @media (max-width: $grid-float-breakpoint-max) {
        position: inherit;
        box-shadow: none;
      }
    }
  }
  .m7-header-right {
    h1 {
      padding-top: 20px;
    }
  }

  .m7-text {
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: left;
      background-color: #f6f6f6;
      padding-bottom: 25px;
    }

    p {
      @media (max-width: $grid-float-breakpoint-max) {
        padding: 0px;
      }
    }
  }
  .orange-border {
    border: 2px solid $orange;
    padding: 45px 60px 45px 42px;
    @media screen and (min-width: 993px) and (max-width: 1403px) {
      padding: 4% 4% 4% 7%;
    }
    @media screen and (max-width: $mobile-breakpoint) {
      padding: 15px;
    }
  }
  .row-eq-height > [class^=col]:first-of-type {
    display: flex;
  }
  .row-eq-height > [class^=col]:first-of-type .black {
    flex-grow: 1;
  }
  .header-job {
    padding-left: 14px;
    margin-top: 44px;
    @media screen and (max-width: $mobile-breakpoint) {
      text-align: center;
      margin: 25px 0;
      padding: 0 15px;
    }
  }
}

  .butoni {
    display: inline-grid;
    margin: 0;
    padding: 0;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding: 15px 50px;
    }
    background: white;
    border-radius: 0;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-color: white;
    margin-top: 2rem;
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-left: 3px;
      margin-top: 1rem;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 15px 30px;
      }
    }
    /*
    a {
    @media screen and (min-width: 992px) {
        &:before {
          content: '';
          position: absolute;
          border-bottom: 2px solid #e67800;
          width: 42%;
          height: 15px;
          left: -42%;
        }
      }
    }
    */
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 0 auto;
    }
    img {
      width: 5px;
      margin: 0px;
      margin-left: 4px;
    }
    .bttn {
      font-size: 14px;
      letter-spacing: 1.5px;
      line-height: 30px;
      padding: 15px 50px;
      margin: 0;
      border: 0;
      text-transform: uppercase;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 0;
      }
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 15px 30px;
        font-size: 12px;
        letter-spacing: 1.3px;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          padding: 0;
        }
      }
    }
  }
  .butoni-orange {
    display: inline-grid;
    margin: 0;
    padding: 0;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding: 15px 50px;
    }
    background: #e67800;
    border-radius: 0;
    box-shadow: none;
    border-color: unset;
    margin-top: 2rem;
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-left: 3px;
      margin-top: 1rem;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 15px 30px;
      }
    }
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 30px auto 0;
    }
    .bttn {
      color: #ffffff;
      font-size: 20px;
      line-height: 30px;
      padding: 6px 20px;
      margin: 0;
      border: 0;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 0;
      }
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 2px 10px;
        font-size: 16px;
        letter-spacing: 1px;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          padding: 0;
        }
      }
    }
  }
  hr {
    margin: 0;
    height: 1px;
    background: #e67800;
    margin-left: -47%;
    margin-top: -30px;
    width: 70%;
    @media screen and (max-width: $mobile-breakpoint) {
      display: block;
      margin: 0;
      height: 1px;
      background: $orange;
      margin-left: -15px;
      margin-top: -30px;
      width: 50%;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: none;
    }
  }

  .text-center-mobile {
    @media screen and (max-width: $mobile-breakpoint) {
      text-align: center;
    }
  }
  .margin-bottom-rem {
    @media screen and (max-width: $mobile-breakpoint) {
      margin-bottom: 4rem;
    }
  }
  .mt-4 {
    margin-top: 2.5rem!important
  }

  .ihransprechpartner {
    margin-top: 80px;
  }
  .fakten-box {
    background: #f6f6f6;
    padding: 21px 25px;
    .row {
      margin: 20px -15px 0
    }
}

  .m7-left {
    @media screen and (max-width: $mobile-breakpoint) {
      padding: 0;
    }
  }
  img {
    @media screen and (max-width: 500px) {
      object-fit: cover;
    }
    
  }
}