.m25 {
  @media (max-width: $grid-float-breakpoint-max) {
    text-align: center;
  }

  hr {
    border: 0 !important;
    padding: 3px;
  }

  .m25-item-ico {
    @media (min-width: $grid-float-breakpoint) {
      float: left;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 20px;
    }

  }

  .m25-item-box {
    display: table;
    margin-bottom: 30px;
    @media (min-width: $grid-float-breakpoint) {
      padding-left: 25px;
    }
    @media (min-width: 1200px) and (max-width: 1300px) {
      padding-left: 17px;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .m25-img {
    margin-top: 110px;
    margin-bottom: 20px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .m25-left {

    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0px;

      p {
        &.light14 {
          padding: 10px;
          margin-bottom: 20px;
          background: $grey-light;
        }
      }
    }

    .m25-left-box {
      @media (min-width: $grid-float-breakpoint) {
        max-width: 100%;
        float: right;
      }
    }

  }

  .m25-right {
    @media (min-width: $grid-float-breakpoint) {
      padding-left: 70px;
      max-width: 476px;
    }
    @media (min-width: 991px) and (max-width: 1188px) {
      padding-left: 0;
    }
  }

  .swiper-pagination-bullets {
    position: initial;
    text-align: center;
    z-index: 10;

    @media (max-width: $grid-float-breakpoint-max) {
      width: 100%;
      margin-bottom: 30px;
    }

    .swiper-pagination-bullet {
      @media (max-width: $grid-float-breakpoint-max) {
        margin: 18px 18px;
        width: 6px;
        height: 6px;
        background: #000;
        opacity: .2;
        outline: none;
      }
      @media (min-width: $grid-float-breakpoint) {
        margin: 18px 18px;
        display: inline-block;
        width: 6px;
        height: 6px;
        background: $primary;
        opacity: .7;
        outline: none;
      }
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background: $primary;

      &:focus {
        outline: none;
      }

      p {
        border: 2px solid $primary;
        padding: 15px;
        border-radius: 50%;
        position: absolute;
        margin: -14px -14px;
        line-height: 0;

        &:focus {
          outline: none;
        }
      }
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    .swiper-container {
      margin-right: 1px;
    }
  }

}