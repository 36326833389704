.m5 {
	text-align: center;
	h2 {
		margin-top: 70px;
		margin-bottom: 55px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-top: 28px;
			margin-bottom: 14px;
		}	
	}
	p.light20 {
		margin-bottom: 93px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 30px;
		}
	}
	p.regular16 {
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 36px;
		}	
	}
	.grey_box
	{
		background: $grey-light;
		@media (min-width: $grid-float-breakpoint) {
			border-left: 15px solid $white;
			border-right: 15px solid $white;
		}
		@media screen and (max-width: $mobile-breakpoint) {
			margin: 0 15px;
		}		
	}
	.icon-box {
		/* width: 200px; */
		width: 190px;
		margin: 0 25px;
		@media screen and (max-width: $mobile-breakpoint) {
			width: 100%;
		}
		@media (min-width: 1380px) {
			width: 230px;
		}
		&.items5 {
			@media (max-width: 1716px) {
				width: 22%;
			}
			@media screen and (max-width: $mobile-breakpoint) {
				width: 100%;
			}
		}
	}
	.round_icon {
		position: relative;
		background: $white;
		border: 2px solid $grey-light;
		border-radius: 50%;
		width: 150px;
		height: 150px;
		margin: 0 auto;
		margin-bottom: 31px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-bottom: 24px;
			width: 130px;
			height: 130px;
		}
		img {
			width: 60px;
			height: 150px;
			&.image-svg {
				width: 40px;
				height: 40px;
				margin-top: 55px;
				@media screen and (max-width: $mobile-breakpoint) {
					margin-top: 45px;
				}
			}
			@media screen and (max-width: $mobile-breakpoint) {
				height: 130px;
			}
		}
	}
	.round_icon:before {
		content: " ";
		position: absolute;
		z-index: 2;
		top: -4px;
		left: -4px;
		right: 0px;
		bottom: 5px;
		border: 2px solid $orange;
		border-radius: 50%;
		width: 154px;
		height: 154px;
		@media screen and (max-width: $mobile-breakpoint) {
			width: 134px;
			height: 134px;
		}
	}
	.btn-arrow {
		margin-top: 53px;
		margin-bottom: 80px;
		@media screen and (max-width: $mobile-breakpoint) {
			margin-top: 0;
			margin-bottom: 30px;
		}
		img {
			width: 5px;
			margin: 15px;
			margin-left: 4px;
		}
	}
	.bottom-space {
		margin-bottom: 138px;
		@media (max-width: $grid-float-breakpoint-max) {
			margin-bottom: 55px;
		}
	}
  .butoni {
    display: inline-grid;
    margin: 2rem auto;
    padding: 0;
    background: white;
    border-radius: 0;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-color: white;
    margin-bottom: 4rem;
	  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		  padding: 15px 50px;
	  }
    @media screen and (max-width: $mobile-breakpoint) {
      margin: 2rem auto;
      margin-top: 0;
		padding: 0;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			padding: 15px 30px;
		}
    }
    img {
      width: 5px;
      margin: 0px;
      margin-left: 4px;
    }
    .bttn {
      font-size: 14px!important;
      letter-spacing: 1.5px;
      line-height: 30px;
      padding: 15px 50px;
      margin: 0;
      border: 0;
      text-transform: uppercase;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			padding: 0;
		}
      @media screen and (max-width: $mobile-breakpoint) {
        padding: 15px 30px;
        font-size: 12px!important;
        letter-spacing: 1.3px;
		  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			  padding: 0;
		  }
      }
    }
  }
}