.m39 {
  .video-player {
    max-width: 100%;
  }
  .m39-title {
    color: $orange;
    margin-bottom: 57px;
  }
}
.vp-video-wrapper.content-area-sibling-enabled {
  background-color: #fff!important;
  background: #fff!important;
}