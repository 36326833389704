.m38 {
  .text-center {
    text-align: center;
    margin-bottom: 30px;
  }
  iframe {
    max-width: 100%;
  }
  &.gated-content{
    position: relative;
    height: 500px;
    .content-details {
      height: 1000px;
      position: absolute;
      padding-top: 250px;
      background: linear-gradient(to top, white, 95%, transparent);
      width: 100%;
    }
  }
}

.gated-content ~ section {
  display: none;
}