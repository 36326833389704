.m35 {
  @media (min-width: $grid-float-breakpoint) {
    background-color: #f6f6f6;
    background: linear-gradient(90deg, #f6f6f6 65%, #ffffff 35%);
    width: 100%;
  }
  @media screen and (max-width: $mobile-breakpoint) {
    padding: 20px 0;
  }


  .left-content {
    @media screen and (max-width: $mobile-breakpoint) {
      background: #f6f6f6;
      padding: 20px 15px;
      h4, p {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .right-content {
    @media screen and (max-width: $mobile-breakpoint) {
      text-align: center;
    }
  }

}