.m49 {
  width: 100%;
  overflow-x: hidden;
  background-size: cover!important;
  text-align: center;
  color: #ffffff;
  padding: 56px 0;
  @media (max-width: 991px) {
    padding-top: 40px;
  }
  .list-items2 {
    margin-bottom: 35px;
    &.no-sub-text {
      margin-top: 40px;
      @media (max-width: 991px) {
        margin-top: 20px;
      }
    }
    .list-item {
      padding-right: 75px;
      display: inline-block;
      @media (max-width: 1149px) {
        display: block;
        padding-right: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      img {
        height: 30px;
        margin: -14px 10px 0 0;
        @media (max-width: 991px) {
          height: 20px;
          margin: -8px 10px 0 0;
        }
      }
    }
    @media (max-width: 1149px) {
      display: inline-block!important;
      text-align: left!important;
    }
  }
    
  h3 {
    font-size: 25px;
    margin-bottom: 12px;
    font-family: $rajdhani;
    font-weight: 500;
    //max-width: 740px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 991px) {
      font-size: 20px;
      max-width: 500px;
      margin-bottom: 7px;
    }
  }
  h3 {
    display: inline-block;
    padding: 0;
  }
  h4 {
    margin-bottom: 30px;
  }
  a {
    display: inline-block;
    background-color: #e67800;
    color: #ffffff;
    width: 260px;
    border-radius: 0;
    padding: 10px 17px;
    font-family: $roboto;
    font-size: 18px;
    font-weight: 500;
    border: 0;
    @media (max-width: 1149px) {
      display: block;
      margin: 0 auto;
    }
    .btn-arroww {
      margin-left: 6px;
    }

  }
  &.image-on-the-right {
    h3 {
      text-align: center;
      @media (max-width: 991px) {
        text-align: center;
      }
    }
    .list-items {
      text-align: left;
      @media (max-width: 1740px) {
        max-width: 1000px;
      }
      @media (max-width: 1200px) {
        max-width: 500px;
      }
      @media (max-width: 991px) {
        text-align: center;
        max-width: 100%;
      }
    }
    .img-right {
      position: absolute;
      right: -10%;
      bottom: -56px;
      max-height: 400px;
      @media (max-width: 1600px) {
        right: -20%;
      }
      @media (max-width: 1387px) {
        display: none;
      }
      @media (max-width: 1120px) {
        display: block;
      }
      @media (max-width: 991px) {
        position: initial;
        display: block;
        margin: 15px auto -56px;
      }
    }
  }
    .hbspt-form {
      .input {
        text-align: left;
      }
      .hs-richtext {
        text-align: left;
        padding-top: 5px;
      }
      .form-columns-3 {
        width: 100%;
        .field {
          @media (min-width: 992px) {
            width: 33%!important;
          }
        }
      }
      p a {
        background-color: unset !important;
        width: unset !important;
        color: black !important;
        padding: 0 !important;
        letter-spacing: 0.3px;
      color: #101010;
      border-bottom: 2px solid #e67800;
      }
      .hs-submit {
        text-align: center;
        @media (min-width: 992px) {
          margin-top: -70px;
        }
      }
      .legal-consent-container {
        @media (min-width: 992px) {
          width: 30%;
        }
      }
    }
    .more-margin-bottom {
      margin-bottom: 20px;
    }
    .hbspt-form .field.hs-fieldtype-booleancheckbox {
      max-width: 450px;
    }
}