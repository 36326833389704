.m6 {
	img {
		width: 100%;
	}
	.visible_desktop {
		margin: 0 auto;
		@media screen and (max-width: $mobile-breakpoint) {
			display: none;
		}
	}
	.visible_mobile {
		display: none;
		@media screen and (max-width: $mobile-breakpoint) {
			display: block;
			margin: 0 auto;
			width: 202px;
		}
	}

}