.m26 {
  @media (max-width: $grid-float-breakpoint-max) {
    text-align: center;
  }

  h1 {
    color: $orange;
  }

  .m26-content {
    margin-top: 51px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 21px;
    }
  }

  .m26-item-box {
    @media (min-width: $grid-float-breakpoint) {
      text-align: right;
      margin-left: auto;
      max-width: 400px;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      text-align: left;
      padding-left: 0px;
      padding-right: 0px;
    }

  }

  .m26-left {
    @media (min-width: $grid-float-breakpoint) {
      max-width: 686px;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      padding: 0px;
    }

    p {
      &.light14 {
        text-align: right;
      }

      &.img-description {
        @media (max-width: $grid-float-breakpoint-max) {
          text-align: left;
          padding: 10px 10px 10px 15px;
          margin-bottom: 0px;
          background: $grey-light;
        }
      }
    }

    .m26-img {
      margin-bottom: 20px;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-bottom: 0px;
      }
    }
  }

  .m26-right {
    @media (min-width: $grid-float-breakpoint) {
      margin-bottom: 30px;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 20px;
    }

    .m26-item-person {
      .regular16 {
        color: $orange;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .light16 {
        margin-bottom: 5px;
      }

      ul {
        padding-left: 0;
        @media screen and (max-width: $grid-float-breakpoint-max) {
          padding-left: 15px;
        }
        li {
          color: #3c3c3c;
          display: block;
          list-style: none;
          margin: 0 20px 16px 0;
          padding: 0;
          position: relative;
          @media screen and (max-width: $grid-float-breakpoint-max) {

          }
          &:after {
            color: $orange;
            content: "\2022";
            display: inline-block;
            font-size: 21px;
            right: -18px;
            position: absolute;
            top: -5px;
            @media screen and (max-width: $grid-float-breakpoint-max) {
              right: 0;
              left: -15px;
              top: -11px;
            }
          }
        }
      }


        a {
          font-weight: 500;
          cursor: pointer;
          line-height: 30px;

          &:after {
            content: "";
            display: inline-block;
            background-image: url(./images/btn-arrow.svg);
            background-repeat: no-repeat;
            width: 9px;
            height: 9px;
            margin-left: 8px;
            transform: rotate(-90deg);
            margin-bottom: 3px;
            margin-right: 3px;
          }

          &.collapsed {
            &:after {
              transform: rotate(90deg);
              margin-bottom: -2px;
            }
          }
        }
      }

      .m26-item-social {
        margin-bottom: 22px;

        .m26cta {
          font-weight: 500;
          @media (min-width: $grid-float-breakpoint) {
            margin-left: 20px;
          }

          &:after {
            content: "";
            display: inline-block;
            background-image: url(./images/btn-arrow.svg);
            background-repeat: no-repeat;
            width: 9px;
            height: 9px;
            margin-left: 8px;
          }
        }
      }
    }
  }