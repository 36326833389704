.m31 {
	h2 {
		text-align: center;
	}
	img {
		border-radius: 50%;
		width: 100px;
	}
	.directors {
		p {
			margin: 0;
		}
	}
	p {
		&.medium25 {
			margin-bottom: 50px;
			@media screen and (max-width: $mobile-breakpoint) {
				text-align: center;
				margin-bottom: 20px;
			}
		}
		&.medium14 {
			text-transform: uppercase;
		}
		&.light16 {
			margin-bottom: 22px;
		}
	}
	.hidden-xs {
		@media screen and (max-width: $mobile-breakpoint) {
			display: none;
		}
	}
	a {
		word-wrap: break-word;
		border: none;
		&.social-link {
			text-transform: uppercase;
			border: none;
			letter-spacing: 1.5px;
			font-weight: $medium;
			margin-right: 18px;
			&:after {
				content: "";
				display: inline-block;
				background-image: url(./images/btn-arrow.svg);
				background-repeat: no-repeat;
				width: 10px;
				height: 10px;
				margin-left: 8px;
			}
			&:hover {
				color: $orange;
			}
		}
		&.alight14 {
			display: initial;
			font-weight: $light;
			font-size: 14px;
			line-height: 20px;
			@media screen and (max-width: $mobile-breakpoint) {
				font-size: 13px;
			}
			&:last:child {
				margin-bottom: 1rem;
			}
		}
		span {
			font-weight: $regular;
			font-size: 14px;
			line-height: 20px;
			@media screen and (max-width: $mobile-breakpoint) {
				font-size: 13px;
			}
		}
		&.margin-bottom-tel {
			margin-bottom: 10px;
		}

	}
	span {
		font-weight: $regular;
		margin-right: 18px;
	}
	.kontakt-nomargine {
		margin: 0;
	}
	.kontakt-person {
		margin-bottom: 45px;
		@media screen and (max-width: $mobile-breakpoint) {
			border-bottom: 1px solid $grey-medium;
			margin-bottom: 30px;
			padding-bottom: 23px;
		}		
	}
	@media screen and (max-width: $mobile-breakpoint) {
		.img-mobile-kontakt {
			width: 145px;
		}
		.info-mobile-kontakt {
			width: calc(100% - 145px);
		}
	}
	@media screen and (max-width: 360px) {
		.info-mobile-kontakt {
			width: 100%;
		}
	}
	.contact_mail_tel {
		min-height: 100px;
		@media screen and (max-width: $mobile-breakpoint) {
			min-height: 0;
		}
	}
}


