.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}
.navbar-toggler {
  position: relative;
  border: none;
  @media (max-width: $grid-float-breakpoint-max) {
    margin-right: 10px;
  }
}
.navbar-toggler span {
  display: block;
  background-color: #444;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  position: relative;
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  -webkit-transition: transform .35s ease-in-out;
  -moz-transition: transform .35s ease-in-out;
  -o-transition: transform .35s ease-in-out;
  transition: transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}
.navbar {
  padding-top: 0px;
  padding-bottom:0px;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 2px 0px 2px 0px;
  }
  > .container-fluid {
    @media (min-width: $grid-float-breakpoint) {
      max-width: 2000px;

    }
  }

  button {
    span {
      height: 2px;
      margin: 6px 0px;
    }
  }
}
.scrolled {
  #primary-menu {
    min-height: 151px;
    @media (min-width: $grid-float-breakpoint) {
      margin-left: auto;
      li {
        @media (min-width: $grid-float-breakpoint) {
          margin-top: 18px;
          margin-bottom: 20px;
          transition: margin 0.3s ease-in-out 0s;
        }
      }
    }
  }
}
#primary-menu {
  @media (min-width: $grid-float-breakpoint) {
    margin-left: 30px;
  }
  @media (min-width: $grid-breakpoints-xl)
  {
    margin-left: auto;
  }
  li {
    &.active {
      a {
        &:before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }
  }

  li {
    @media (min-width: $grid-float-breakpoint) {
      margin-top: 29px;
      margin-bottom: 30px;
      transition: margin .3s linear;

    }
    @media (max-width: $grid-float-breakpoint-max) {
      border-bottom: 1px solid $grey-medium;
    }

    &:first-child {
      @media (max-width: $grid-float-breakpoint-max) {
        border-top: 1px solid $grey-medium;
      }
    }
    a {
      text-transform: uppercase;
      font-size: 18px;
      text-decoration: none;
      position: relative;
      font-weight: 500;
      border: none;
      color: $dark;
      @media (max-width: $grid-float-breakpoint-max) {
        font-size: 16px;
        text-transform: none;
        font-weight: $medium;
        margin-left: 20px;
        color: $dark;
      }
      @media (min-width: $grid-float-breakpoint) {
        line-height: 1.3!important;
        padding: 5px 0px;
        margin-left: 43px;
        margin-right: 30px;
        text-align: center;
        &:hover {
          &:before {
            visibility: visible;
            transform: scaleX(1);
            // width: 100%;
          }
        }

        &:before {

          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: $primary;
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out 0s;
          /*
            content: "";
            display:inline-block;
            width:0;
            height:1px;
            background: $primary;
            position: absolute;
            left: 0px;
            bottom:0px;
            transition:0.2s ease-in;
          */
        }
      }
      @media (min-width: 1276px) and (max-width: 1643px) {
        text-align: center;
        width: min-content;
      }
      @media (min-width: 1200px) and (max-width: 1300px) {
        margin-right: 0px;
      }

      @media (min-width: 992px) and (max-width: 1070px) {
        margin-left: 0px!important
      }
      @media (min-width: 992px) and (max-width: 1070px) {
        letter-spacing: initial;
      }

      &:after {
        @media (max-width: $grid-float-breakpoint-max)
        {
          content: "";
          display: inline-block;
          background-image: url(./images/btn-arrow.svg);
          background-repeat: no-repeat;
          width: 9px;
          height: 9px;
          margin-right: 20px;
          float: right;
          margin-top: 14px;
        }
      }
    }
  }
}

#secondary-menu {
  @media (min-width: $grid-float-breakpoint) {
    padding-bottom: 8px;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    margin-bottom: 0px;
  }

  li {
    @media (max-width: $grid-float-breakpoint-max) {
      border-bottom: 1px solid $grey-medium;
      padding: 8px 0px;
      position: relative;
    }

    @media (min-width: 991px) and (max-width: 1645px) {
      width: max-content!important;
    }

    a {
      font-size: 14px;
      font-weight: $regular;
      color: #000;
      text-decoration: none;
      border-bottom: none;
      @media (max-width: $grid-float-breakpoint-max) {
        margin-left: 20px;
        font-weight: 300;
        display: block;
      }

      @media (min-width: $grid-float-breakpoint) {
        padding: 0px;
        margin: 0px 0px 0px 25px;
        &:hover {
          color: $primary;
        }
      }
    }

    &:first-child {
      a {
        @media (min-width: $grid-float-breakpoint) {
          margin: 0;
        }
      }
    }

    &.active {
      a {
        color: $primary;
      }
    }

    &:after {
      @media (min-width: $grid-float-breakpoint) {
        content: " ";
        background: $orange;
        height: 4px;
        width: 4px;
        display: inline-block;
        border-radius: 50%;
        margin: 0px 0px 3px 25px;
      }

      @media (max-width: $grid-float-breakpoint-max)
      {
        content: "";
        display: inline-block;
        background-image: url(./images/btn-arrow.svg);
        background-repeat: no-repeat;
        width: 9px;
        height: 9px;
        margin-right: 20px;
        float: right;
        margin-top: 14px;
        position: absolute;
        right: 0px;
        top: 10px;
      }
    }
    &:last-child {
      @media (max-width: $grid-float-breakpoint-max) {
        border-bottom: none;
      }

      &:after {
        @media (min-width: $grid-float-breakpoint) {
          content: none;
        }
      }
    }
  }
}
#primary-menu {
  @media (min-width: 1200px) and (max-width: 1275px) {
    padding-right: 33px;
  }
  @media (min-width: 992px) and (max-width: 1275px) {
    position: absolute!important;
    right: 0px;
    top: 29px;
    transition: top 0.3s ease-in-out 0s;
  }
  li {
    @media (min-width: 992px) and (max-width: 1275px) {
      margin: 0;
    }
  }
}
#secondary-menu {
  @media (min-width: 992px) and (max-width: 1275px) {
    position: absolute!important;
    right: 34px;
    top: 64px;
    transition: top 0.3s ease-in-out 0s;
  }
  li {
    @media (min-width: 992px) and (max-width: 1275px) {
      margin: 0;
    }
  }
}
#navbar {
  @media (min-width: 992px) and (max-width: 1275px) {
    height: 135px;
    transition: height 0.3s ease-in-out 0s;
  }
}
.scrolled {
  #navbar {
    @media (min-width: 992px) and (max-width: 1275px) {
      height: 70px;
      transition: height 0.3s ease-in-out 0s;
    }
  }
  #primary-menu {
    min-height: initial;
    @media (min-width: 992px) and (max-width: 1275px) {
      top: 10px;
      transition: top 0.3s ease-in-out 0s;
    }
    li {
      @media (min-width: 992px) and (max-width: 1275px) {
        margin: 0;
      }
    }
  }
  #secondary-menu {
    @media (min-width: 992px) and (max-width: 1275px) {
      top: 30px;
      transition: top 0.3s ease-in-out 0s;
    }
    li {
      @media (min-width: 992px) and (max-width: 1275px) {
        margin: 0;
      }
    }
  }
}