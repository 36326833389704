.m13 {
  @media (max-width: $grid-float-breakpoint-max) {
    text-align: center;
  }
  .m13-title {
    margin-bottom: 47px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-top: 38px;
      margin-bttom: 33px;
    }
  }

  .m13-item-ico {
    @media (min-width: $grid-float-breakpoint) {
      float: left;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 20px;
    }

  }
  .m13-item-box {
    display: table;
    margin-bottom: 30px;
    padding-left: 30px;
    @media (max-width: $grid-float-breakpoint-max) {
      display: block;
      padding: 0 16px;
    }
  }
  .m13-img {
    margin-bottom: 60px;
    @media (max-width: $grid-float-breakpoint-max) {
      margin-bottom: 24px;
    }
  }

  .bullet {
    margin: 10px auto;
    display: block;
    width: 4px;
    height: 4px;
    background: #e67800;
    outline: none;
    border-radius: 100%;
  }

  h6 {
    color: $orange;
    text-transform: uppercase;
  }


  .shadow-small {
    &.bcg-white {
      .m13-box-item {
      padding: 25px 20px;
        border-bottom: 1px solid $grey-medium;

        p {
          margin-bottom: 0px;
        }
      }

    }
  }
}